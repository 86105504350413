import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	addRoleError,
	addRoleSuccess,
	editRoleError,
	editRoleSuccess,
	getRoleError,
	getRoleRequest,
	getRoleSuccess,
} from './actions';
import {
	ADD_ROLE_REQUEST,
	EDIT_ROLE_REQUEST,
	GET_ROLE_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

import {
	createRoleApi,
	editRoleApi,
	getRoleApi,
} from '../../services/auth/roleAPI/roleApi';

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addRoles({ payload: { role, history } }) {
	try {
		const response = yield call(createRoleApi, role);
		if (response && response.success) {
			yield put(addRoleSuccess(response));
			yield put(getRoleRequest());
			history.push('/view-roles');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addRoleError(error));
	}
}
function* getRoles() {
	try {
		const response = yield call(getRoleApi);
		if (response && response.success) {
			yield put(getRoleSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getRoleError(error));
	}
}
function* editRoles({ payload: { role, history } }) {
	try {
		const response = yield call(editRoleApi, role);
		if (response && response.success) {
			yield put(editRoleSuccess(response));
			yield put(getRoleRequest());
			history.push('/view-roles');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editRoleError(error));
	}
}

function* rolesSaga() {
	yield takeEvery(ADD_ROLE_REQUEST, addRoles);
	yield takeEvery(GET_ROLE_REQUEST, getRoles);
	yield takeEvery(EDIT_ROLE_REQUEST, editRoles);
}

export default rolesSaga;
