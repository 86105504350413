import {
	API_ERROR,
	LOGIN_RESET,
	LOGIN_SUCCESS,
	LOGIN_USER,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
} from './actionTypes';

const initialState = {
	successData: null,
	error: null,
	loading: null,
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				loading: true,
			};
			break;
		case LOGIN_SUCCESS:
			state = {
				...state,
				loading: false,
				successData: action.payload,
			};
			break;
		case LOGIN_RESET:
			state = initialState;
			break;
		case LOGOUT_USER:
			state = { ...state, isUserLogout: false };
			break;
		case LOGOUT_USER_SUCCESS:
			state = { ...initialState, isUserLogout: true };
			break;
		case API_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
				isUserLogout: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default login;
