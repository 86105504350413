export const ADD_ROLE_PERMISSIONS_REQUEST = 'ADD_ROLE_PERMISSIONS_REQUEST';
export const ADD_ROLE_PERMISSIONS_SUCCESS = 'ADD_ROLE_PERMISSIONS_SUCCESS';
export const ADD_ROLE_PERMISSIONS_ERROR = 'ADD_ROLE_PERMISSIONS_ERROR';
export const ADD_ROLE_PERMISSIONS_RESET = 'ADD_ROLE_PERMISSIONS_RESET';

export const GET_ROLE_PERMISSIONS_REQUEST = 'GET_ROLE_PERMISSIONS_REQUEST';
export const GET_ROLE_PERMISSIONS_SUCCESS = 'GET_ROLE_PERMISSIONS_SUCCESS';
export const GET_ROLE_PERMISSIONS_ERROR = 'GET_ROLE_PERMISSIONS_ERROR';
export const GET_ROLE_PERMISSIONS_RESET = 'GET_ROLE_PERMISSIONS_RESET';

export const DELETE_ROLE_PERMISSIONS_REQUEST =
	'DELETE_ROLE_PERMISSIONS_REQUEST';
export const DELETE_ROLE_PERMISSIONS_SUCCESS =
	'DELETE_ROLE_PERMISSIONS_SUCCESS';
export const DELETE_ROLE_PERMISSIONS_ERROR = 'DELETE_ROLE_PERMISSIONS_ERROR';
export const DELETE_ROLE_PERMISSIONS_RESET = 'DELETE_ROLE_PERMISSIONS_RESET';

export const EDIT_ROLE_PERMISSIONS_REQUEST = 'EDIT_ROLE_PERMISSIONS_REQUEST';
export const EDIT_ROLE_PERMISSIONS_SUCCESS = 'EDIT_ROLE_PERMISSIONS_SUCCESS';
export const EDIT_ROLE_PERMISSIONS_ERROR = 'EDIT_ROLE_PERMISSIONS_ERROR';
export const EDIT_ROLE_PERMISSIONS_RESET = 'EDIT_ROLE_PERMISSIONS_RESET';
