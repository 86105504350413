import {
  ADD_QUESTION_AT_END,
  ADD_QUESTION_IN_BETWEEN,
  ADD_QUESTION_TITLE,
  ALL_QUESTION_COMPLETE_VIEW,
  ALL_QUESTION_COMPLETE_VIEW_RESET,
  ALL_QUESTION_SURVEY,
  CANCEL_QUESTION,
  DELETE_QUESTION,
  EDIT_QUESTION,
  EDIT_QUESTION_DATA,
  EDIT_QUESTION_DATA_RESET,
  QUESTION_SURVEY_RESET,
  REQUIRED_QUESTION,
} from "./actionTypes";

// Invite Member with email

export const allQuestionInAssessment = (surveyPayload) => {
  return {
    type: ALL_QUESTION_SURVEY,
    payload: surveyPayload,
  };
};

export const addQuestionTitle = (surveyPayload) => {
  return {
    type: ADD_QUESTION_TITLE,
    payload: surveyPayload,
  };
};
export const editQuestion = (surveyPayload) => {
  return {
    type: EDIT_QUESTION,
    payload: surveyPayload,
  };
};
export const editQuestionData = (surveyPayload) => {
  return {
    type: EDIT_QUESTION_DATA,
    payload: surveyPayload,
  };
};
export const editQuestionDataReset = () => {
  return {
    type: EDIT_QUESTION_DATA_RESET,
  };
};
export const isRequiredQuestion = (surveyPayload) => {
  return {
    type: REQUIRED_QUESTION,
    payload: surveyPayload,
  };
};
export const cancelQuestion = (surveyPayload) => {
  return {
    type: CANCEL_QUESTION,
    payload: surveyPayload,
  };
};

export const deleteQuestion = (surveyPayload) => {
  return {
    type: DELETE_QUESTION,
    payload: surveyPayload,
  };
};

export const addQuestionAtEndOfAssessment = (surveyPayload) => {
  return {
    type: ADD_QUESTION_AT_END,
    payload: surveyPayload,
  };
};
export const addQuestionInBetweenAssessment = (surveyPayload) => {
  return {
    type: ADD_QUESTION_IN_BETWEEN,
    payload: surveyPayload,
  };
};

export const questionInAssessmentReset = () => {
  return {
    type: QUESTION_SURVEY_RESET,
  };
};

export const allQuestionCompleteView = (surveyPayload) => {
  return {
    type: ALL_QUESTION_COMPLETE_VIEW,
    payload: surveyPayload,
  };
};
export const allQuestionCompleteViewReset = () => {
  return {
    type: ALL_QUESTION_COMPLETE_VIEW_RESET,
  };
};
