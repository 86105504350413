import {
  ADD_QUESTIONS_INITIATE_ASSESSMENT,
  ASSESSMENT_QUESTION_ERROR,
  CURRENT_QUESTION_ID,
  CURRENT_QUESTION_ID_RESET,
  GET_CURRENT_TAB_ID,
  GET_PIPING_ID,
  GET_PIPING_ID_RESET,
  GET_QUESTION_SIZE,
  GET_STATS,
  INITIATE_ASSESSMENT_ERROR,
  INITIATE_ASSESSMENT_REQUEST,
  INITIATE_ASSESSMENT_RESET,
  INITIATE_ASSESSMENT_SUCCESS,
  PREDEFINED_QUESTION_ERROR,
  REQUIRED_QUESTION_ERROR,
  SAME_OPTIONS_ERROR,
  UPDATE_INITIATE_ASSESSMENT,
  VALIDATION_ERROR,
} from "./actionTypes";

export const initiateAssessmentRequest = (assessmentPayload) => {
  return {
    type: INITIATE_ASSESSMENT_REQUEST,
    payload: assessmentPayload,
  };
};

export const initiateAssessmentSuccess = (message) => {
  return {
    type: INITIATE_ASSESSMENT_SUCCESS,
    payload: message,
  };
};

export const initiateAssessmentError = (message) => {
  return {
    type: INITIATE_ASSESSMENT_ERROR,
    payload: message,
  };
};

export const initiateAssessmentReset = () => {
  return {
    type: INITIATE_ASSESSMENT_RESET,
  };
};

export const initiateAssessmentAddQuestionnaire = (questionPayload) => {
  return {
    type: ADD_QUESTIONS_INITIATE_ASSESSMENT,
    payload: questionPayload,
  };
};

export const updateAssessmentInStore = (questionPayload) => {
  return {
    type: UPDATE_INITIATE_ASSESSMENT,
    payload: questionPayload,
  };
};

export const getPipingIDReset = () => {
  return {
    type: GET_PIPING_ID_RESET,
  };
};

export const getPipingID = (questionID) => {
  return {
    type: GET_PIPING_ID,
    payload: questionID,
  };
};

export const getCurrentQuestionID = (questionID) => {
  return {
    type: CURRENT_QUESTION_ID,
    payload: questionID,
  };
};

export const getQuestionsSize = (questionSize) => {
  return {
    type: GET_QUESTION_SIZE,
    payload: questionSize,
  };
};

export const getCurrentQuestionIDReset = (questionID) => {
  return {
    type: CURRENT_QUESTION_ID_RESET,
    payload: questionID,
  };
};

export const getStatistics = (stats) => {
  return {
    type: GET_STATS,
    payload: stats,
  };
};

export const getCurrentTabID = (tabID) => {
  return {
    type: GET_CURRENT_TAB_ID,
    payload: tabID,
  };
};

export const assessmentQuestionError = (message) => {
  return {
    type: ASSESSMENT_QUESTION_ERROR,
    payload: message,
  };
};
export const sameOptionsError = (message) => {
  return {
    type: SAME_OPTIONS_ERROR,
    payload: message,
  };
};

export const predefinedQuestionError = (message) => {
  return {
    type: PREDEFINED_QUESTION_ERROR,
    payload: message,
  };
};
export const requiredQuestionError = (message) => {
  return {
    type: REQUIRED_QUESTION_ERROR,
    payload: message,
  };
};
export const validationError = (message) => {
  return {
    type: VALIDATION_ERROR,
    payload: message,
  };
};
