// Get Profile
export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_RESET = "PROFILE_RESET";

// Update Profile
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const RESET_EDIT_PROFILE_FLAG = "RESET_EDIT_PROFILE_FLAG";

// Change Profile
export const PROFILE_IMAGE_REQUEST = "PROFILE_IMAGE_REQUEST";
export const PROFILE_IMAGE_SUCCESS = "PROFILE_IMAGE_SUCCESS";
export const PROFILE_IMAGE_ERROR = "PROFILE_IMAGE_ERROR";
export const RESET_PROFILE_IMAGE_FLAG = "RESET_PROFILE_IMAGE_FLAG";

// Email Verification
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_REQUEST";
