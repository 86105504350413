import {
  CREATE_ASSESSMENT_ERROR,
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_RESET,
  CREATE_ASSESSMENT_SUCCESS,
} from "./actionTypes";

const initialStateCreateAssessment = {
  successData: null,
  error: null,
  loading: null,
};

const createAssessment = (state = initialStateCreateAssessment, action) => {
  switch (action.type) {
    case CREATE_ASSESSMENT_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
        error: null,
      };
      break;

    case CREATE_ASSESSMENT_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case CREATE_ASSESSMENT_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case CREATE_ASSESSMENT_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { createAssessment };
