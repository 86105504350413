import { create } from '../common/http.service';

const CREATE_MEMBER_URL = '/ses-verify-email';

const GET_MEMBER_URL = '/ses-get-verified-list';

const DELETE_ADMIN_EMAIL = '/ses-delete-email';

export const addAdminEmailApi = async (payload) => {
	const res = await create(CREATE_MEMBER_URL, payload, true);

	return res;
};

export const getAdminEmailsApi = async (payload) => {
	const res = await create(GET_MEMBER_URL, payload, true);

	return res;
};

export const deleteAdminEmailApi = async (payload) => {
	const res = await create(DELETE_ADMIN_EMAIL, payload, true);

	return res;
};
