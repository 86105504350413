import {
  SHARE_SINGLE_SURVEY_ERROR,
  SHARE_SINGLE_SURVEY_REQUEST,
  SHARE_SINGLE_SURVEY_RESET,
  SHARE_SINGLE_SURVEY_SUCCESS,
} from "./actionTypes";

// Invite Member with email
export const shareSingleAssessmentRequest = (surveyPayload) => {
  return {
    type: SHARE_SINGLE_SURVEY_REQUEST,
    payload: surveyPayload,
  };
};

export const shareSingleAssessmentSuccess = (message) => {
  return {
    type: SHARE_SINGLE_SURVEY_SUCCESS,
    payload: message,
  };
};

export const shareSingleAssessmentError = (message) => {
  return {
    type: SHARE_SINGLE_SURVEY_ERROR,
    payload: message,
  };
};

export const shareSingleAssessmentReset = () => {
  return {
    type: SHARE_SINGLE_SURVEY_RESET,
  };
};
