import {
	GET_INFO_CATEGORIES_ERROR,
	GET_INFO_CATEGORIES_REQUEST,
	GET_INFO_CATEGORIES_RESET,
	GET_INFO_CATEGORIES_SUCCESS,
	SEARCH_INFO_CATEGORIES_ERROR,
	SEARCH_INFO_CATEGORIES_REQUEST,
	SEARCH_INFO_CATEGORIES_RESET,
	SEARCH_INFO_CATEGORIES_SUCCESS,
} from './actionTypes';

// Get Information Categories
export const getInfoCategoriesRequest = (info) => {
	return {
		type: GET_INFO_CATEGORIES_REQUEST,
		payload: info,
	};
};

export const getInfoCategoriesSuccess = (message) => {
	return {
		type: GET_INFO_CATEGORIES_SUCCESS,
		payload: message,
	};
};

export const getInfoCategoriesError = (message) => {
	return {
		type: GET_INFO_CATEGORIES_ERROR,
		payload: message,
	};
};

export const getInfoCategoriesReset = () => {
	return {
		type: GET_INFO_CATEGORIES_RESET,
	};
};

// Search Information Categories
export const searchInfoCategoriesRequest = (searchInfo) => {
	return {
		type: SEARCH_INFO_CATEGORIES_REQUEST,
		payload: searchInfo,
	};
};

export const searchInfoCategoriesSuccess = (message) => {
	return {
		type: SEARCH_INFO_CATEGORIES_SUCCESS,
		payload: message,
	};
};

export const searchInfoCategoriesError = (message) => {
	return {
		type: SEARCH_INFO_CATEGORIES_ERROR,
		payload: message,
	};
};

export const searchInfoCategoriesReset = () => {
	return {
		type: SEARCH_INFO_CATEGORIES_RESET,
	};
};
