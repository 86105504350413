import {
	CREATE_CHART_DASHBOARD_ERROR,
	CREATE_CHART_DASHBOARD_REQUEST,
	CREATE_CHART_DASHBOARD_RESET,
	CREATE_CHART_DASHBOARD_SUCCESS,
	DELETE_CHART_DASHBOARD_ERROR,
	DELETE_CHART_DASHBOARD_REQUEST,
	DELETE_CHART_DASHBOARD_RESET,
	DELETE_CHART_DASHBOARD_SUCCESS,
	UPDATE_CHART_DASHBOARD_ERROR,
	UPDATE_CHART_DASHBOARD_REQUEST,
	UPDATE_CHART_DASHBOARD_RESET,
	UPDATE_CHART_DASHBOARD_SUCCESS,
	VIEW_CHART_DASHBOARD_ERROR,
	VIEW_CHART_DASHBOARD_REQUEST,
	VIEW_CHART_DASHBOARD_RESET,
	VIEW_CHART_DASHBOARD_SUCCESS,
	VIEW_PUBLIC_DASHBOARD_ERROR,
	VIEW_PUBLIC_DASHBOARD_REQUEST,
	VIEW_PUBLIC_DASHBOARD_RESET,
	VIEW_PUBLIC_DASHBOARD_SUCCESS,
	VIEW_SINGLE_CHART_DASHBOARD_ERROR,
	VIEW_SINGLE_CHART_DASHBOARD_REQUEST,
	VIEW_SINGLE_CHART_DASHBOARD_RESET,
	VIEW_SINGLE_CHART_DASHBOARD_SUCCESS,
} from './actionTypes';

const initialStateCreateChartDashboard = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateUpdateChartDashboard = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteChartDashboard = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateViewChartDashboard = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateViewSingleChartDashboard = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateViewPublicDashboard = {
	successData: null,
	error: null,
	loading: null,
};

// Create Chart Dashboard
const createChartDashboard = (
	state = initialStateCreateChartDashboard,
	action
) => {
	switch (action.type) {
		case CREATE_CHART_DASHBOARD_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;

		case CREATE_CHART_DASHBOARD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case CREATE_CHART_DASHBOARD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case CREATE_CHART_DASHBOARD_RESET:
			state = initialStateCreateChartDashboard;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

// Update Chart Dashboard
const updateChartDashboard = (
	state = initialStateUpdateChartDashboard,
	action
) => {
	switch (action.type) {
		case UPDATE_CHART_DASHBOARD_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;

		case UPDATE_CHART_DASHBOARD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case UPDATE_CHART_DASHBOARD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case UPDATE_CHART_DASHBOARD_RESET:
			state = initialStateUpdateChartDashboard;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

// Delete Chart Dashboard
const deleteChartDashboard = (
	state = initialStateDeleteChartDashboard,
	action
) => {
	switch (action.type) {
		case DELETE_CHART_DASHBOARD_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;

		case DELETE_CHART_DASHBOARD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_CHART_DASHBOARD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case DELETE_CHART_DASHBOARD_RESET:
			state = initialStateDeleteChartDashboard;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

// View List of All Chart Dashboard
const viewChartDashboard = (state = initialStateViewChartDashboard, action) => {
	switch (action.type) {
		case VIEW_CHART_DASHBOARD_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;

		case VIEW_CHART_DASHBOARD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case VIEW_CHART_DASHBOARD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case VIEW_CHART_DASHBOARD_RESET:
			state = initialStateViewChartDashboard;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

// View Single Chart Dashboard
const viewSingleChartDashboard = (
	state = initialStateViewSingleChartDashboard,
	action
) => {
	switch (action.type) {
		case VIEW_SINGLE_CHART_DASHBOARD_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;

		case VIEW_SINGLE_CHART_DASHBOARD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case VIEW_SINGLE_CHART_DASHBOARD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case VIEW_SINGLE_CHART_DASHBOARD_RESET:
			state = initialStateViewSingleChartDashboard;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

// View Public Chart Dashboard
const viewPublicDashboard = (
	state = initialStateViewPublicDashboard,
	action
) => {
	switch (action.type) {
		case VIEW_PUBLIC_DASHBOARD_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;

		case VIEW_PUBLIC_DASHBOARD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case VIEW_PUBLIC_DASHBOARD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case VIEW_PUBLIC_DASHBOARD_RESET:
			state = initialStateViewPublicDashboard;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export {
	createChartDashboard,
	updateChartDashboard,
	deleteChartDashboard,
	viewChartDashboard,
	viewSingleChartDashboard,
	viewPublicDashboard,
};
