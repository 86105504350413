export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_ERROR = "ADD_TEMPLATE_ERROR";
export const ADD_TEMPLATE_RESET = "ADD_TEMPLATE_RESET";

export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_ERROR = "GET_TEMPLATE_ERROR";
export const GET_TEMPLATE_RESET = "GET_TEMPLATE_RESET";

export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_ERROR = "DELETE_TEMPLATE_ERROR";
export const DELETE_TEMPLATE_RESET = "DELETE_TEMPLATE_RESET";

export const VIEW_ALL_TEMPLATE_REQUEST = "VIEW_ALL_TEMPLATE_REQUEST";
export const VIEW_ALL_TEMPLATE_SUCCESS = "VIEW_ALL_TEMPLATE_SUCCESS";
export const VIEW_ALL_TEMPLATE_ERROR = "VIEW_ALL_TEMPLATE_ERROR";
export const VIEW_ALL_TEMPLATE_RESET = "VIEW_ALL_TEMPLATE_RESET";
