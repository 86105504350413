import {
  GET_QUIZ_ANALYSIS_ERROR,
  GET_QUIZ_ANALYSIS_REQUEST,
  GET_QUIZ_ANALYSIS_RESET,
  GET_QUIZ_ANALYSIS_SUCCESS,
} from "./actionTypes";

// Assessment Analysis
export const getQuizAnalysisRequest = (surveyPayload) => {
  return {
    type: GET_QUIZ_ANALYSIS_REQUEST,
    payload: surveyPayload,
  };
};

export const getQuizAnalysisSuccess = (message) => {
  return {
    type: GET_QUIZ_ANALYSIS_SUCCESS,
    payload: message,
  };
};

export const getQuizAnalysisError = (message) => {
  return {
    type: GET_QUIZ_ANALYSIS_ERROR,
    payload: message,
  };
};

export const getQuizAnalysisReset = () => {
  return {
    type: GET_QUIZ_ANALYSIS_RESET,
  };
};
