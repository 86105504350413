import {
  ADD_FEEDBACK_ERROR,
  ADD_FEEDBACK_REQUEST,
  ADD_FEEDBACK_RESET,
  ADD_FEEDBACK_SUCCESS,
} from "./actionTypes";

export const addFeedbackRequest = (message) => {
  return {
    type: ADD_FEEDBACK_REQUEST,
    payload: message,
  };
};

export const addFeedbackSuccess = (message) => {
  return {
    type: ADD_FEEDBACK_SUCCESS,
    payload: message,
  };
};

export const addFeedbackError = (message) => {
  return {
    type: ADD_FEEDBACK_ERROR,
    payload: message,
  };
};

export const addFeedbackReset = () => {
  return {
    type: ADD_FEEDBACK_RESET,
  };
};
