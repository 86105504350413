import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import assignPermissions from "./auth/assigned-permissions/reducer";
import changePassword from "./auth/changepwd/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import {
  editProfile,
  getProfile as profile,
  profileImage,
} from "./auth/profile/reducer";

import addFeedback from "./auth/feedback/reducer";

import userRegistration from "./auth/register/reducer";
import resetPassword from "./auth/resetpwd/reducer";

// payment
import Payments from "./billing/reducer";

// role
import { addRole, editRole, getRole } from "./roles/reducer";

// permissions
import {
  addPermission,
  editPermission,
  getPermission,
} from "./permissions/reducer";

// user-role
import { addUserRole, deleteUserRole } from "./users/roles/reducer";

// user-permissions
import { addUserPermissions } from "./users/permission/reducer";

// role-permissions
import { addRolePermissions } from "./roles/permissions/reducer";

// admin defined emails
import {
  addAdminEmail,
  deleteAdminEmail,
  getAdminEmailList,
} from "./emails/admin-defined/reducer";

// member + verified-members
import {
  activeMember,
  addMember,
  addMemberEmail,
  editMember,
  getMember,
  getVerifiedMember,
} from "./members/reducer";

// create assessment

import {
  addCompanyLogo,
  addIntroBG,
} from "./common/assessments/create-assessment/intro/reducer";

import { addChannel, getChannel } from "./channels/reducer";
import { addTemplate, getTemplate, viewAllTemplate } from "./templates/reducer";

import { addDisBG } from "./common/assessments/create-assessment/disqualification/reducer";

import { createAssessment } from "./common/assessments/create-assessment/reducer";

import { AIQuestions } from "./common/assessments/ai-question-generation/reducer";

import {
  assessmentCurrentActiveTab,
  assessmentQuestionError,
  initiateAssessment,
  predefinedQuestionError,
  questionID,
  questionSize,
  questionStats,
  requiredQuestionError,
  sameOptionsError,
  validationError,
} from "./common/assessments/default/reducer";

import { uploadImage } from "./common/assessments/image-uploader/reducer";

import {
  addSegmentAssessment,
  getSegmentsAssessment,
} from "./common/assessments/create-assessment/segments/reducer";

import {
  addRecurrenceAssessment,
  getRecurrencesAssessment,
} from "./common/assessments/create-assessment/recurrence/reducer";

import {
  assessmentQuestions,
  assessmentQuestionsCompleteView,
  editQuestionInfo,
} from "./common/assessments/assessment-questionnaire/reducer";

import { viewAllAssessmentPublic } from "./common/assessments/public-assessment/reducer";
import {
  addToQuestionBank,
  completeQuestionBank,
  searchQuestionBank,
  singleQuestionBank,
} from "./common/assessments/question-bank/reducer";
import { assessmentSuccess } from "./common/assessments/share-assessment/assessment-success/reducer";
import { channelDetails } from "./common/assessments/share-assessment/channel-details/reducer";
import { shareSingleAssessment } from "./common/assessments/share-assessment/reducer";
import {
  assessmentAnswers,
  assessmentUsers,
  viewSingleAssessment,
} from "./common/assessments/single-assessment/reducer";
import { updateAssessment } from "./common/assessments/update-assessment/reducer";
import {
  viewAllAssessment,
  viewAllAssessmentStats,
} from "./common/assessments/view-assessment/reducer";

import { allChartsViewData } from "./common/assessments/analysis/survey/charts/reducer";
import { npiCSATStats } from "./common/assessments/analysis/survey/miscellaneous/reducer";
import { assessmentAnalysis } from "./common/assessments/analysis/survey/reducer";
import { questionSentiments } from "./common/assessments/analysis/survey/sentiments/reducer";
import { assessmentAnalysisSummary } from "./common/assessments/analysis/survey/summaries/reducer";

import { allChartsDataQuiz } from "./common/assessments/analysis/quiz/charts/reducer";
import { feedbackReports } from "./common/assessments/analysis/quiz/complete-report/reducer";
import { npiCSATStatsQuiz } from "./common/assessments/analysis/quiz/miscellaneous/reducer";
import { quizAnalysis } from "./common/assessments/analysis/quiz/reducer";
import { quizSummary } from "./common/assessments/analysis/quiz/summaries/reducer";

import {
  createChartDashboard,
  deleteChartDashboard,
  updateChartDashboard,
  viewChartDashboard,
  viewPublicDashboard,
  viewSingleChartDashboard,
} from "./common/assessments/graph-dashboard/reducer";

import {
  addAttributes,
  editAttributes,
  getAttributes,
} from "./audience/attributes/reducer";
import {
  addAudienceAttributes,
  getAudienceAttributes,
} from "./audience/audience-attributes/reducer";

import {
  addAudience,
  addAudienceDupCheck,
  editAudience,
  getAudience,
} from "./audience/reducer";

import {
  addCriteria,
  addCriteriaDupCheck,
  editCriteria,
  getCriteria,
  getCriteriaList,
} from "./common/criteria-grading/reducer";

import { cloneAudience } from "./audience/share-audience/reducer";

import {
  addEmailTemplate,
  editEmailTemplate,
  emailTemplateShare,
  getEmailTemplate,
  getEmailTemplateList,
} from "./audience/email-template/reducer";

import { getInfoCategories, searchInfoCategories } from "./information/reducer";

import { LOGOUT_USER } from "./auth/login/actionTypes";

import chat from "./chat/reducer";
import countdownTime from "./common/count_down/reducer";

import {
  addNewNode,
  deleteNode,
  editNode,
  getFamilyTree,
} from "./familyTree/reducer";

const rootReducer = combineReducers({
  // public
  Layout,

  // Auth User
  Login,
  ForgetPassword,
  changePassword,
  resetPassword,

  userRegistration, //register user

  // user-profile
  profile,
  editProfile,
  profileImage,
  assignPermissions,
  addFeedback,

  // payments
  Payments,
  // role
  addRole,
  getRole,
  editRole,

  // permissions
  addPermission,
  editPermission,
  getPermission,

  // user-role
  addUserRole,
  deleteUserRole,

  // user-permissions
  addUserPermissions,
  addRolePermissions,

  // admin-team-members
  addMember,
  activeMember,
  getMember,
  editMember,
  addMemberEmail,

  // verified email and registered team members
  getVerifiedMember,

  // admin-defined-emails for amazon verification
  addAdminEmail,
  getAdminEmailList,
  deleteAdminEmail,

  // add company logo in intro page
  addCompanyLogo,

  // add background image in introduction page
  addIntroBG,

  // add background image in disqualification page
  addDisBG,

  // add new assessment initial state
  initiateAssessment,

  // add image
  uploadImage,

  // AI Question Info
  AIQuestions,

  // assessment \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  //Current Question ID
  questionID,
  // Questions length in a assessment
  questionSize,
  // Question Stats (minutes to complete/percentage assessment attempted)
  questionStats,
  // Single Question View current tab ID
  assessmentCurrentActiveTab,

  //question to Edit or Add
  editQuestionInfo,
  // all questions in current opened assessment
  assessmentQuestions,
  // question error
  assessmentQuestionError,
  // same options of a question error
  sameOptionsError,
  //required question error
  requiredQuestionError,
  // predefined view question error
  predefinedQuestionError,
  // predefined view question error
  validationError,
  //assessment answers submission
  assessmentAnswers,
  // survey user who are attempting survey
  assessmentUsers,
  //assessment count down time
  countdownTime,
  // Dashboard All Assessments (Open,Draft,Responses)
  viewAllAssessmentStats,
  //create assessment
  createAssessment,

  // update assessment
  updateAssessment,
  // create question bank
  addToQuestionBank,
  // complete question bank
  completeQuestionBank,
  // get one question from question bank
  singleQuestionBank,

  // search question from question bank
  searchQuestionBank,
  // view single assessment
  viewSingleAssessment,

  // all question in preview/publish assessment
  assessmentQuestionsCompleteView,
  // share links for a assessment
  shareSingleAssessment,
  // publish channel details
  channelDetails,
  // share links creation success
  assessmentSuccess,

  // get all assessment list
  viewAllAssessment,

  // get all assessment list public
  viewAllAssessmentPublic,

  // get assessment statistics
  assessmentAnalysis,
  // get assessment summary
  assessmentAnalysisSummary,
  // NPI CSAT Questions Statistics
  npiCSATStats,
  // get question sentiments
  questionSentiments,
  // all charts data
  allChartsViewData,

  // get assessment statistics
  quizAnalysis,
  // get assessment summary
  quizSummary,
  // NPI CSAT Questions Statistics
  npiCSATStatsQuiz,
  // all charts data
  allChartsDataQuiz,

  feedbackReports,

  // assessment \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  createChartDashboard,
  updateChartDashboard,
  deleteChartDashboard,
  viewChartDashboard,
  viewSingleChartDashboard,
  viewPublicDashboard,

  // segments

  addSegmentAssessment,
  getSegmentsAssessment,

  // recurrence

  addRecurrenceAssessment,
  getRecurrencesAssessment,

  // audience
  addAudience,
  addAudienceDupCheck,
  getAudience,
  editAudience,

  // criteria
  addCriteria,
  addCriteriaDupCheck,
  getCriteria,
  editCriteria,
  getCriteriaList,

  //share-audience
  cloneAudience,

  // attributes
  addAttributes,
  getAttributes,
  editAttributes,

  // audience-attributes
  addAudienceAttributes,
  getAudienceAttributes,

  // email-templates

  addEmailTemplate,
  getEmailTemplate,
  editEmailTemplate,
  getEmailTemplateList,
  emailTemplateShare,

  /// channels
  addChannel,
  getChannel,

  // templates

  addTemplate,
  getTemplate,
  viewAllTemplate,

  // family tree

  addNewNode,
  deleteNode,
  editNode,
  getFamilyTree,

  // information

  infoCategories: getInfoCategories,
  searchInfoCategories,
  chat,
});

const rootReducer1 = (state, action) => {
  if (action.type === LOGOUT_USER) {
    localStorage.clear();
    state = undefined;
  }
  return rootReducer(state, action);
};

export default rootReducer1;
