import {
  ASSESSMENT_SUCCESS_ERROR,
  ASSESSMENT_SUCCESS_REQUEST,
  ASSESSMENT_SUCCESS_RESET,
  ASSESSMENT_SUCCESS_SUCCESS,
} from "./actionTypes";

// Invite Member with email
export const assessmentSuccessRequest = (assessmentPayload) => {
  return {
    type: ASSESSMENT_SUCCESS_REQUEST,
    payload: assessmentPayload,
  };
};

export const assessmentSuccessSuccess = (message) => {
  return {
    type: ASSESSMENT_SUCCESS_SUCCESS,
    payload: message,
  };
};

export const assessmentSuccessError = (message) => {
  return {
    type: ASSESSMENT_SUCCESS_ERROR,
    payload: message,
  };
};

export const assessmentSuccessReset = () => {
  return {
    type: ASSESSMENT_SUCCESS_RESET,
  };
};
