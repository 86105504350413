import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import attributesSaga from "./audience/attributes/saga";
import audienceAttributesSaga from "./audience/audience-attributes/saga";
import emailTemplateSaga from "./audience/email-template/saga";
import audienceSaga from "./audience/saga";
import audienceCloneSaga from "./audience/share-audience/saga";
import changePasswordSaga from "./auth/changepwd/saga";
import addFeedbackSaga from "./auth/feedback/saga";
import ForgotPasswordSaga from "./auth/forgetpwd/saga";
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import RegistrationSaga from "./auth/register/saga";
import resetPasswordSaga from "./auth/resetpwd/saga";
import PaymentSaga from "./billing/saga";
import channelSaga from "./channels/saga";
import questionsAISaga from "./common/assessments/ai-question-generation/saga";
import criteriaSaga from "./common/criteria-grading/saga";
import adminEmailSaga from "./emails/admin-defined/saga";
import membersSaga from "./members/saga";
import permissionsSaga from "./permissions/saga";
import rolePermissionsSaga from "./roles/permissions/saga";
import rolesSaga from "./roles/saga";

import surveyDisSaga from "./common/assessments/create-assessment/disqualification/saga";
import surveyIntroSaga from "./common/assessments/create-assessment/intro/saga";
import surveyRecurrenceSaga from "./common/assessments/create-assessment/recurrence/saga";
import createSurveySaga from "./common/assessments/create-assessment/saga";
import surveySegmentSaga from "./common/assessments/create-assessment/segments/saga";
import chartDashboardsSaga from "./common/assessments/graph-dashboard/saga";
import viewAllSurveyPublicSaga from "./common/assessments/public-assessment/saga";
import questionBankSaga from "./common/assessments/question-bank/saga";
import surveySuccessSaga from "./common/assessments/share-assessment/assessment-success/saga";
import channelDetailsSaga from "./common/assessments/share-assessment/channel-details/saga";
import shareSingleAssessmentSaga from "./common/assessments/share-assessment/saga";
import viewSingleAssessmentSaga from "./common/assessments/single-assessment/saga";
import updateSurveySaga from "./common/assessments/update-assessment/saga";
import viewAllSurveySaga from "./common/assessments/view-assessment/saga";

import templateSaga from "./templates/saga";
import userPermissionsSaga from "./users/permission/saga";
import userRolesSaga from "./users/roles/saga";

import assessmentAnalysisSaga from "./common/assessments/analysis/survey/saga";
import surveySentimentsSaga from "./common/assessments/analysis/survey/sentiments/saga";
import surveySummarySaga from "./common/assessments/analysis/survey/summaries/saga";

import feedbackReportsSaga from "./common/assessments/analysis/quiz/complete-report/saga";
import quizAnalysisSaga from "./common/assessments/analysis/quiz/saga";
import quizSummarySaga from "./common/assessments/analysis/quiz/summaries/saga";

import UploadImageSaga from "./common/assessments/image-uploader/saga";
import informationSaga from "./information/saga";

import familyTreeSaga from "./familyTree/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),

    // auth
    fork(AuthSaga), // login
    fork(RegistrationSaga), // register
    fork(ProfileSaga), // profile
    fork(ForgotPasswordSaga),
    fork(changePasswordSaga),
    fork(resetPasswordSaga),
    fork(addFeedbackSaga),

    //payments
    fork(PaymentSaga),

    // role
    fork(rolesSaga),

    // user-role
    fork(userRolesSaga),

    //user-permissions
    fork(userPermissionsSaga),

    // role-permissions
    fork(rolePermissionsSaga),

    // permissions
    fork(permissionsSaga),

    // admin-defined-emails
    fork(adminEmailSaga),

    // members
    fork(membersSaga),

    // surveys
    fork(createSurveySaga),
    fork(surveySegmentSaga),
    fork(surveyRecurrenceSaga),
    fork(questionsAISaga),
    fork(surveyIntroSaga),
    fork(surveyDisSaga),
    fork(updateSurveySaga),
    fork(questionBankSaga),

    // Sharing survey
    fork(viewSingleAssessmentSaga),
    fork(shareSingleAssessmentSaga),
    fork(channelDetailsSaga),
    fork(surveySuccessSaga),

    // Analysis
    fork(viewAllSurveySaga),
    fork(viewAllSurveyPublicSaga),
    fork(assessmentAnalysisSaga),
    fork(surveySentimentsSaga),
    fork(surveySummarySaga),
    fork(chartDashboardsSaga),

    fork(channelSaga),
    fork(templateSaga),

    // Audience and Attributes
    fork(audienceSaga),
    fork(attributesSaga),
    fork(audienceAttributesSaga),
    fork(audienceCloneSaga),

    // Email Templates
    fork(emailTemplateSaga),

    //information
    fork(informationSaga),

    // upload image
    fork(UploadImageSaga),

    // family tree
    fork(familyTreeSaga),

    fork(criteriaSaga),

    fork(feedbackReportsSaga),
    fork(quizAnalysisSaga),
    fork(quizSummarySaga),
  ]);
}
