import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import { AiOutlineHome } from "react-icons/ai";

import NotificationDropdown from "../../components/Common/NotificationDropdown";

//import images
// import logoBG from "../../assets/images/logo.png";
// import logoBG from "../../assets/images/Logo/Antlere-Logo.svg";
import { AutoDismissAlert } from "../../components/Common/AutoDismissAlert";

//import Components

import ProfileDropdown from "../../components/Common/ProfileDropdown";
import { loginReset } from "../../store/actions";

const logoSm = require("../../assets/images/logo-antlere.png");
const logoBG = require("../../assets/images/Logo/Antlere-Logo.svg");

const Header = ({
  headerClass,
  setIsSidebarChanged,
  isSurveyHeader = false,
}) => {
  const { successData } = useSelector((state) => state.Login);

  const toogleMenuBtn = () => {
    setIsSidebarChanged((prev) => (prev == "lg" ? "sm" : "lg"));
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            {!isSurveyHeader ? (
              <>
                {" "}
                <div className="d-flex">
                  <div className="navbar-brand-box horizontal-logo">
                    <Link
                      to="/"
                      className="logo logo-dark"
                      onClick={() =>
                        localStorage.setItem("activeLink", "dashboard")
                      }
                    >
                      <span className="logo-sm">
                        <img src={logoSm} alt="Antlere" height="22" />
                      </span>
                      <span className="logo-lg">
                        <img src={logoSm} alt="Antlere" height="17" />
                      </span>
                    </Link>

                    <Link
                      to="/"
                      className="logo logo-light"
                      onClick={() =>
                        localStorage.setItem("activeLink", "dashboard")
                      }
                    >
                      <span className="logo-sm">
                        <img src={logoSm} alt="Antlere" height="22" />
                      </span>
                      <span className="logo-lg">
                        <img src={logoSm} alt="Antlere" height="17" />
                      </span>
                    </Link>
                  </div>
                  <button
                    onClick={toogleMenuBtn}
                    type="button"
                    className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                    id="topnav-hamburger-icon"
                  >
                    <span className="hamburger-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </button>

                  {/* <SearchOption /> */}
                </div>{" "}
                {successData ? (
                  <AutoDismissAlert
                    color="success"
                    message={successData?.message || "No Message Found"}
                    resetDispatch={loginReset}
                    customClass="w-25 mb-0"
                  />
                ) : null}
              </>
            ) : (
              <div
                className="d-flex justify-content-between align-items-center px-4"
                style={{ width: "40%" }}
              >
                <div className=" me-4">
                  <Link
                    to="/"
                    className="logo logo-dark"
                    onClick={() =>
                      localStorage.setItem("activeLink", "dashboard")
                    }
                  >
                    <span className="logo-sm">
                      <img src={logoBG} alt="Antlere" width={170} />
                    </span>
                    <span className="logo-lg">
                      <img src={logoBG} alt="Antlere" width={170} />
                    </span>
                  </Link>

                  <Link
                    to="/"
                    className="logo logo-light"
                    onClick={() =>
                      localStorage.setItem("activeLink", "dashboard")
                    }
                  >
                    <span className="logo-sm">
                      <img src={logoBG} alt="Antlere" width={170} />
                    </span>
                    <span className="logo-lg">
                      <img src={logoBG} alt="Antlere" width={170} />
                    </span>
                  </Link>
                </div>

                {/* <SearchOption searchClass={"ms-4"} /> */}
              </div>
            )}

            <div className="d-flex align-items-center">
              {isSurveyHeader ? (
                <>
                  <Link
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    to="/"
                    id="back_to_dashboard"
                    style={{ padding: "0.5rem 0.7rem" }}
                  >
                    <AiOutlineHome size={20} />
                  </Link>{" "}
                  <UncontrolledTooltip
                    placement="right"
                    target="back_to_dashboard"
                  >
                    Go to Dashboard
                  </UncontrolledTooltip>
                </>
              ) : //  userPackage < 2 ? (
              //   <Link className="btn btn-primary" to="/billing">
              //     Upgrade Plans
              //   </Link>
              // ) :
              null}

              {/* <FullScreenDropdown /> */}

              {/* <LightDark
								layoutMode={layoutModeType}
								onChangeLayoutMode={onChangeLayoutMode}
							/> */}

              <NotificationDropdown />

              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  headerClass: PropTypes.any,
  isSurveyHeader: PropTypes.bool,
  setIsSidebarChanged: PropTypes.func,
};

export default Header;
