import {
  ADD_TEMPLATE_ERROR,
  ADD_TEMPLATE_REQUEST,
  ADD_TEMPLATE_RESET,
  ADD_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_ERROR,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  GET_TEMPLATE_ERROR,
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_RESET,
  GET_TEMPLATE_SUCCESS,
  VIEW_ALL_TEMPLATE_ERROR,
  VIEW_ALL_TEMPLATE_REQUEST,
  VIEW_ALL_TEMPLATE_RESET,
  VIEW_ALL_TEMPLATE_SUCCESS,
} from "./actionTypes";

// Create Template
export const addTemplateRequest = (channel) => {
  return {
    type: ADD_TEMPLATE_REQUEST,
    payload: channel,
  };
};

export const addTemplateSuccess = (message) => {
  return {
    type: ADD_TEMPLATE_SUCCESS,
    payload: message,
  };
};

export const addTemplateError = (message) => {
  return {
    type: ADD_TEMPLATE_ERROR,
    payload: message,
  };
};

export const addTemplateReset = () => {
  return {
    type: ADD_TEMPLATE_RESET,
  };
};

// Get Template
export const getTemplateRequest = (channel) => {
  return {
    type: GET_TEMPLATE_REQUEST,
    payload: channel,
  };
};

export const getTemplateSuccess = (message) => {
  return {
    type: GET_TEMPLATE_SUCCESS,
    payload: message,
  };
};

export const getTemplateError = (message) => {
  return {
    type: GET_TEMPLATE_ERROR,
    payload: message,
  };
};

export const getTemplateReset = (message) => {
  return {
    type: GET_TEMPLATE_RESET,
  };
};

// Delete Template
export const deleteTemplateRequest = (member, history) => {
  return {
    type: DELETE_TEMPLATE_REQUEST,
    payload: { member, history },
  };
};

export const deleteTemplateSuccess = (message) => {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    payload: message,
  };
};

export const deleteTemplateError = (message) => {
  return {
    type: DELETE_TEMPLATE_ERROR,
    payload: message,
  };
};

export const viewAllTemplateRequest = (message) => {
  return {
    type: VIEW_ALL_TEMPLATE_REQUEST,
    payload: message,
  };
};

export const viewAllTemplateSuccess = (message) => {
  return {
    type: VIEW_ALL_TEMPLATE_SUCCESS,
    payload: message,
  };
};

export const viewAllTemplateError = (message) => {
  return {
    type: VIEW_ALL_TEMPLATE_ERROR,
    payload: message,
  };
};

export const viewAllTemplateReset = () => {
  return {
    type: VIEW_ALL_TEMPLATE_RESET,
  };
};
