export const ADD_CHANNEL_REQUEST = 'ADD_CHANNEL_REQUEST';
export const ADD_CHANNEL_SUCCESS = 'ADD_CHANNEL_SUCCESS';
export const ADD_CHANNEL_ERROR = 'ADD_CHANNEL_ERROR';
export const ADD_CHANNEL_RESET = 'ADD_CHANNEL_RESET';

export const GET_CHANNEL_REQUEST = 'GET_CHANNEL_REQUEST';
export const GET_CHANNEL_SUCCESS = 'GET_CHANNEL_SUCCESS';
export const GET_CHANNEL_ERROR = 'GET_CHANNEL_ERROR';
export const GET_CHANNEL_RESET = 'GET_CHANNEL_RESET';

export const DELETE_CHANNEL_REQUEST = 'DELETE_CHANNEL_REQUEST';
export const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';
export const DELETE_CHANNEL_ERROR = 'DELETE_CHANNEL_ERROR';
export const DELETE_CHANNEL_RESET = 'DELETE_CHANNEL_RESET';
