import { create } from "../common/http.service";

const GET_INFO_CATEGORIES = "/kb/get-categories";
const SEARCH_INFO_CATEGORIES = "/kb/search-categories";

export const searchInfoCategoriesApi = async (payload) => {
  const res = await create(SEARCH_INFO_CATEGORIES, payload, true);
  return res;
};

export const getInfoCategoriesApi = async (payload) => {
  const res = await create(GET_INFO_CATEGORIES, payload, true);
  return res;
};
