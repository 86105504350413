import {
	ADD_CHANNEL_ERROR,
	ADD_CHANNEL_REQUEST,
	ADD_CHANNEL_RESET,
	ADD_CHANNEL_SUCCESS,
	DELETE_CHANNEL_ERROR,
	DELETE_CHANNEL_REQUEST,
	DELETE_CHANNEL_SUCCESS,
	GET_CHANNEL_ERROR,
	GET_CHANNEL_REQUEST,
	GET_CHANNEL_RESET,
	GET_CHANNEL_SUCCESS,
} from './actionTypes';

// Create Channel
export const addChannelRequest = (channel) => {
	return {
		type: ADD_CHANNEL_REQUEST,
		payload: channel,
	};
};

export const addChannelSuccess = (message) => {
	return {
		type: ADD_CHANNEL_SUCCESS,
		payload: message,
	};
};

export const addChannelError = (message) => {
	return {
		type: ADD_CHANNEL_ERROR,
		payload: message,
	};
};

export const addChannelReset = () => {
	return {
		type: ADD_CHANNEL_RESET,
	};
};

// Get Channel
export const getChannelRequest = (channel) => {
	return {
		type: GET_CHANNEL_REQUEST,
		payload: channel,
	};
};

export const getChannelSuccess = (message) => {
	return {
		type: GET_CHANNEL_SUCCESS,
		payload: message,
	};
};

export const getChannelError = (message) => {
	return {
		type: GET_CHANNEL_ERROR,
		payload: message,
	};
};

export const getChannelReset = (message) => {
	return {
		type: GET_CHANNEL_RESET,
	};
};

// Delete Channel
export const deleteChannelRequest = (member, history) => {
	return {
		type: DELETE_CHANNEL_REQUEST,
		payload: { member, history },
	};
};

export const deleteChannelSuccess = (message) => {
	return {
		type: DELETE_CHANNEL_SUCCESS,
		payload: message,
	};
};

export const deleteChannelError = (message) => {
	return {
		type: DELETE_CHANNEL_ERROR,
		payload: message,
	};
};
