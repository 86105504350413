import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	createUserRoleApi,
	deleteUserRoleApi,
	editUserRoleApi,
	getUserRoleApi,
} from '../../../services/auth/users/userRoleApi';
import {
	addUserRoleError,
	addUserRoleReset,
	addUserRoleSuccess,
	deleteUserRoleError,
	deleteUserRoleSuccess,
	editUserRoleError,
	editUserRoleSuccess,
	getUserRoleError,
	getUserRoleSuccess,
} from './actions';
import {
	ADD_USER_ROLE_REQUEST,
	DELETE_USER_ROLE_REQUEST,
	EDIT_USER_ROLE_REQUEST,
	GET_USER_ROLE_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addUserRoles({ payload: user }) {
	try {
		const response = yield call(createUserRoleApi, user);
		if (response && response.success) {
			yield put(addUserRoleSuccess(response));
			yield put(addUserRoleReset(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addUserRoleError(error));
	}
}
function* getUserRoles() {
	try {
		const response = yield call(getUserRoleApi);
		if (response && response.success) {
			yield put(getUserRoleSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getUserRoleError(error));
	}
}
function* editUserRoles({ payload: { role, history } }) {
	try {
		const response = yield call(editUserRoleApi, role);
		if (response && response.success) {
			yield put(editUserRoleSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editUserRoleError(error));
	}
}

function* deleteUserRoles({ payload: role }) {
	try {
		const response = yield call(deleteUserRoleApi, role);
		if (response && response.success) {
			yield put(deleteUserRoleSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(deleteUserRoleError(error));
	}
}

function* userRolesSaga() {
	yield takeEvery(ADD_USER_ROLE_REQUEST, addUserRoles);
	yield takeEvery(GET_USER_ROLE_REQUEST, getUserRoles);
	yield takeEvery(EDIT_USER_ROLE_REQUEST, editUserRoles);
	yield takeEvery(DELETE_USER_ROLE_REQUEST, deleteUserRoles);
}

export default userRolesSaga;
