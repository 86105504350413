import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import { getFeedbackReportsError, getFeedbackReportsSuccess } from "./actions";

import { feedbackReportsApi } from "../../../../../../services/quiz/quiz-analysis/quizAnalysisApi";
import { GET_FEEDBACK_REPORTS_REQUEST } from "./actionTypes";

function* getFeedbackReports({ payload: surveyPayload }) {
  try {
    const response = yield call(feedbackReportsApi, surveyPayload);
    if (response && response.success) {
      yield put(getFeedbackReportsSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getFeedbackReportsError(error));
  }
}

function* feedbackReportsSaga() {
  yield takeEvery(GET_FEEDBACK_REPORTS_REQUEST, getFeedbackReports);
}

export default feedbackReportsSaga;
