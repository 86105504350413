import {
	ADD_ADMIN_EMAIL_ERROR,
	ADD_ADMIN_EMAIL_REQUEST,
	ADD_ADMIN_EMAIL_RESET,
	ADD_ADMIN_EMAIL_SUCCESS,
	DELETE_ADMIN_EMAIL_ERROR,
	DELETE_ADMIN_EMAIL_REQUEST,
	DELETE_ADMIN_EMAIL_SUCCESS,
	EDIT_ADMIN_EMAIL_ERROR,
	EDIT_ADMIN_EMAIL_REQUEST,
	EDIT_ADMIN_EMAIL_RESET,
	EDIT_ADMIN_EMAIL_SUCCESS,
	GET_ADMIN_EMAIL_LIST_ERROR,
	GET_ADMIN_EMAIL_LIST_REQUEST,
	GET_ADMIN_EMAIL_LIST_RESET,
	GET_ADMIN_EMAIL_LIST_SUCCESS,
} from './actionTypes';

const initialStateAddAdminEmail = {
	successData: null,
	error: null,
	loading: null,
};

const initialStateGetAdminEmail = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteAdminEmail = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditAdminEmail = {
	successData: null,
	error: null,
	loading: null,
};

const addAdminEmail = (state = initialStateAddAdminEmail, action) => {
	switch (action.type) {
		case ADD_ADMIN_EMAIL_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_ADMIN_EMAIL_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_ADMIN_EMAIL_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_ADMIN_EMAIL_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

const getAdminEmailList = (state = initialStateGetAdminEmail, action) => {
	switch (action.type) {
		case GET_ADMIN_EMAIL_LIST_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_ADMIN_EMAIL_LIST_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_ADMIN_EMAIL_LIST_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_ADMIN_EMAIL_LIST_RESET:
			state = initialStateGetAdminEmail;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteAdminEmail = (state = initialStateDeleteAdminEmail, action) => {
	switch (action.type) {
		case DELETE_ADMIN_EMAIL_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_ADMIN_EMAIL_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_ADMIN_EMAIL_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editAdminEmail = (state = initialStateEditAdminEmail, action) => {
	switch (action.type) {
		case EDIT_ADMIN_EMAIL_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_ADMIN_EMAIL_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_ADMIN_EMAIL_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_ADMIN_EMAIL_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addAdminEmail, deleteAdminEmail, getAdminEmailList, editAdminEmail };
