import {
	ADD_AUDIENCE_DUPE,
	ADD_AUDIENCE_DUPE_RESET,
	ADD_AUDIENCE_ERROR,
	ADD_AUDIENCE_NON_DUPE,
	ADD_AUDIENCE_NON_DUPE_RESET,
	ADD_AUDIENCE_REQUEST,
	ADD_AUDIENCE_RESET,
	ADD_AUDIENCE_SUCCESS,
	DELETE_AUDIENCE_ERROR,
	DELETE_AUDIENCE_REQUEST,
	DELETE_AUDIENCE_SUCCESS,
	EDIT_AUDIENCE_ERROR,
	EDIT_AUDIENCE_REQUEST,
	EDIT_AUDIENCE_RESET,
	EDIT_AUDIENCE_SUCCESS,
	GET_AUDIENCE_ERROR,
	GET_AUDIENCE_REQUEST,
	GET_AUDIENCE_RESET,
	GET_AUDIENCE_SUCCESS,
} from './actionTypes';

// Create Audience
export const addAudienceRequest = (audience) => {
	return {
		type: ADD_AUDIENCE_REQUEST,
		payload: audience,
	};
};

export const addAudienceSuccess = (message) => {
	return {
		type: ADD_AUDIENCE_SUCCESS,
		payload: message,
	};
};

export const addAudienceError = (message) => {
	return {
		type: ADD_AUDIENCE_ERROR,
		payload: message,
	};
};

export const addAudienceReset = () => {
	return {
		type: ADD_AUDIENCE_RESET,
	};
};

// Duplicate Audience
export const addAudienceDupes = (audience) => {
	return {
		type: ADD_AUDIENCE_DUPE,
		payload: audience,
	};
};
export const addAudienceDupesReset = () => {
	return {
		type: ADD_AUDIENCE_DUPE_RESET,
	};
};

// Non-Duplicate Audience
export const addAudienceNonDupes = (audience) => {
	return {
		type: ADD_AUDIENCE_NON_DUPE,
		payload: audience,
	};
};
export const addAudienceNonDupesReset = () => {
	return {
		type: ADD_AUDIENCE_NON_DUPE_RESET,
	};
};

// Get Audience
export const getAudienceRequest = (audience) => {
	return {
		type: GET_AUDIENCE_REQUEST,
		payload: audience,
	};
};

export const getAudienceSuccess = (message) => {
	return {
		type: GET_AUDIENCE_SUCCESS,
		payload: message,
	};
};

export const getAudienceError = (message) => {
	return {
		type: GET_AUDIENCE_ERROR,
		payload: message,
	};
};

export const getAudienceReset = (message) => {
	return {
		type: GET_AUDIENCE_RESET,
	};
};

// Delete Audience
export const deleteAudienceRequest = (role) => {
	return {
		type: DELETE_AUDIENCE_REQUEST,
		payload: role,
	};
};

export const deleteAudienceSuccess = (message) => {
	return {
		type: DELETE_AUDIENCE_SUCCESS,
		payload: message,
	};
};

export const deleteAudienceError = (message) => {
	return {
		type: DELETE_AUDIENCE_ERROR,
		payload: message,
	};
};

// Delete Audience
export const editAudienceRequest = (audience) => {
	return {
		type: EDIT_AUDIENCE_REQUEST,
		payload: audience,
	};
};

export const editAudienceSuccess = (message) => {
	return {
		type: EDIT_AUDIENCE_SUCCESS,
		payload: message,
	};
};

export const editAudienceError = (message) => {
	return {
		type: EDIT_AUDIENCE_ERROR,
		payload: message,
	};
};
export const editAudienceReset = () => {
	return {
		type: EDIT_AUDIENCE_RESET,
	};
};
