import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import { getQuizAnalysisError, getQuizAnalysisSuccess } from "./actions";

import { quizAnalysisApi } from "../../../../../services/quiz/quiz-analysis/quizAnalysisApi";
import { GET_QUIZ_ANALYSIS_REQUEST } from "./actionTypes";

function* getAnalysisOfQuiz({ payload: surveyPayload }) {
  try {
    const response = yield call(quizAnalysisApi, {
      ...surveyPayload,
      quiz_id: surveyPayload.survey_id,
    });

    if (response && response.success) {
      yield put(getQuizAnalysisSuccess(response));
      // yield call(getAnalysisOfQuizAll, {
      // 	payloadAll: { ...surveyPayload, request_type: 2 },
      // });
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getQuizAnalysisError(error));
  }
}

function* quizAnalysisSaga() {
  yield takeEvery(GET_QUIZ_ANALYSIS_REQUEST, getAnalysisOfQuiz);
}

export default quizAnalysisSaga;
