// Layout Type
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_LAYOUT_MODE = 'CHANGE_LAYOUT_MODE';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_LAYOUT_POSITION = 'CHANGE_LAYOUT_POSITION';
export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME';
export const CHANGE_SIDEBAR_SIZE_TYPE = 'CHANGE_SIDEBAR_SIZE_TYPE';
export const CHANGE_SIDEBAR_VIEW = 'CHANGE_SIDEBAR_VIEW';
export const RESET_VALUE = 'RESET_VALUE';
