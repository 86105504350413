import {
	ADD_SEGMENT_ERROR,
	ADD_SEGMENT_REQUEST,
	ADD_SEGMENT_RESET,
	ADD_SEGMENT_SUCCESS,
	GET_SEGMENTS_ERROR,
	GET_SEGMENTS_REQUEST,
	GET_SEGMENTS_RESET,
	GET_SEGMENTS_SUCCESS,
} from './actionTypes';

// Invite Member with email

export const addSegmentRequest = (segment) => {
	return {
		type: ADD_SEGMENT_REQUEST,
		payload: segment,
	};
};

export const addSegmentSuccess = (message) => {
	return {
		type: ADD_SEGMENT_SUCCESS,
		payload: message,
	};
};

export const addSegmentError = (message) => {
	return {
		type: ADD_SEGMENT_ERROR,
		payload: message,
	};
};

export const addSegmentReset = () => {
	return {
		type: ADD_SEGMENT_RESET,
	};
};

export const getSegmentsRequest = (surveyID) => {
	return {
		type: GET_SEGMENTS_REQUEST,
		payload: surveyID,
	};
};

export const getSegmentsSuccess = (message) => {
	return {
		type: GET_SEGMENTS_SUCCESS,
		payload: message,
	};
};

export const getSegmentsError = (message) => {
	return {
		type: GET_SEGMENTS_ERROR,
		payload: message,
	};
};

export const getSegmentsReset = (message) => {
	return {
		type: GET_SEGMENTS_RESET,
	};
};
