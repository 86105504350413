import {
  CREATE_ASSESSMENT_ERROR,
  CREATE_ASSESSMENT_LOCAL_STORAGE,
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_REQUEST_OTHER,
  CREATE_ASSESSMENT_RESET,
  CREATE_ASSESSMENT_SUCCESS,
} from "./actionTypes";

// Invite Member with email
export const createAssessmentRequest = (assessmentPayload) => {
  return {
    type: CREATE_ASSESSMENT_REQUEST,
    payload: assessmentPayload,
  };
};
export const createAssessmentOtherRequest = (assessmentPayload) => {
  return {
    type: CREATE_ASSESSMENT_REQUEST_OTHER,
    payload: assessmentPayload,
  };
};

export const createAssessmentLocalStorage = (message) => {
  return {
    type: CREATE_ASSESSMENT_LOCAL_STORAGE,
    payload: message,
  };
};

export const createAssessmentSuccess = (message) => {
  return {
    type: CREATE_ASSESSMENT_SUCCESS,
    payload: message,
  };
};

export const createAssessmentError = (message) => {
  return {
    type: CREATE_ASSESSMENT_ERROR,
    payload: message,
  };
};

export const createAssessmentReset = () => {
  return {
    type: CREATE_ASSESSMENT_RESET,
  };
};

export const copyAssessmentRequest = (assessmentPayload) => {
  return {
    type: CREATE_ASSESSMENT_REQUEST,
    payload: assessmentPayload,
  };
};

export const copyAssessmentSuccess = (message) => {
  return {
    type: CREATE_ASSESSMENT_SUCCESS,
    payload: message,
  };
};

export const copyAssessmentError = (message) => {
  return {
    type: CREATE_ASSESSMENT_ERROR,
    payload: message,
  };
};

export const copyAssessmentReset = () => {
  return {
    type: CREATE_ASSESSMENT_RESET,
  };
};
