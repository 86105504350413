import { call, put, select, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  createTemplateApi,
  viewAllTemplatePublicApi,
  viewSingleTemplateApi,
} from "../../services/template/templateApis.js";
import { initiateAssessmentSuccess } from "../actions.js";
import {
  ADD_TEMPLATE_REQUEST,
  GET_TEMPLATE_REQUEST,
  VIEW_ALL_TEMPLATE_REQUEST,
} from "./actionTypes";
import {
  addTemplateError,
  addTemplateSuccess,
  getTemplateError,
  getTemplateSuccess,
  viewAllTemplateError,
  viewAllTemplateSuccess,
} from "./actions";

//If role is send successfully send mail link then dispatch redux action's are directly from here.

function* createTemplate({ payload: surveyPayload }) {
  try {
    let newData;
    const response = yield call(createTemplateApi, surveyPayload);
    if (response && response.success) {
      if (surveyPayload._id) {
        newData = surveyPayload;
      } else {
        let { survey_id, ...rest } = response.message;

        newData = { ...surveyPayload, ...rest, _id: survey_id };
      }

      yield put(initiateAssessmentSuccess(newData));
      yield put(addTemplateSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addTemplateError(error));
  }
}

function* getTemplate({ payload: channel }) {
  try {
    const response = yield call(viewSingleTemplateApi, channel);
    if (response && response.success) {
      yield put(getTemplateSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getTemplateError(error));
  }
}

function* viewAllTemplate({ payload: message }) {
  const { keepState, ...rest } = message;
  try {
    const prevState = yield select((state) => state.viewAllTemplate);
    const response = yield call(viewAllTemplatePublicApi, rest);
    if (response && response.success) {
      if (keepState) {
        yield put(
          viewAllTemplateSuccess({
            ...response,
            data: {
              ...response.data,
              data: [...prevState.successData.data.data, ...response.data.data],
            },
          })
        );
      } else {
        yield put(viewAllTemplateSuccess(response));
      }
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewAllTemplateError(error));
  }
}

function* templateSaga() {
  yield takeEvery(ADD_TEMPLATE_REQUEST, createTemplate);
  yield takeEvery(GET_TEMPLATE_REQUEST, getTemplate);
  yield takeEvery(VIEW_ALL_TEMPLATE_REQUEST, viewAllTemplate);
}

export default templateSaga;
