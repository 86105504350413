import {
	ADD_PERMISSION_ERROR,
	ADD_PERMISSION_REQUEST,
	ADD_PERMISSION_RESET,
	ADD_PERMISSION_SUCCESS,
	DELETE_PERMISSION_ERROR,
	DELETE_PERMISSION_REQUEST,
	DELETE_PERMISSION_SUCCESS,
	EDIT_PERMISSION_ERROR,
	EDIT_PERMISSION_REQUEST,
	EDIT_PERMISSION_RESET,
	EDIT_PERMISSION_SUCCESS,
	GET_PERMISSION_ERROR,
	GET_PERMISSION_REQUEST,
	GET_PERMISSION_RESET,
	GET_PERMISSION_SUCCESS,
} from './actionTypes';

// Create Permissions
export const addPermissionsRequest = (permission, history) => {
	return {
		type: ADD_PERMISSION_REQUEST,
		payload: { permission, history },
	};
};

export const addPermissionsSuccess = (message) => {
	return {
		type: ADD_PERMISSION_SUCCESS,
		payload: message,
	};
};

export const addPermissionsError = (message) => {
	return {
		type: ADD_PERMISSION_ERROR,
		payload: message,
	};
};

export const addPermissionsReset = () => {
	return {
		type: ADD_PERMISSION_RESET,
	};
};

// Get Permissions
export const getPermissionsRequest = () => {
	return {
		type: GET_PERMISSION_REQUEST,
	};
};

export const getPermissionsSuccess = (message) => {
	return {
		type: GET_PERMISSION_SUCCESS,
		payload: message,
	};
};

export const getPermissionsError = (message) => {
	return {
		type: GET_PERMISSION_ERROR,
		payload: message,
	};
};

export const getPermissionsReset = (message) => {
	return {
		type: GET_PERMISSION_RESET,
	};
};

// Delete Permissions
export const deletePermissionsRequest = (permission, history) => {
	return {
		type: DELETE_PERMISSION_REQUEST,
		payload: { permission, history },
	};
};

export const deletePermissionsSuccess = (message) => {
	return {
		type: DELETE_PERMISSION_SUCCESS,
		payload: message,
	};
};

export const deletePermissionsError = (message) => {
	return {
		type: DELETE_PERMISSION_ERROR,
		payload: message,
	};
};

// Delete Permissions
export const editPermissionsRequest = (permission, history) => {
	return {
		type: EDIT_PERMISSION_REQUEST,
		payload: { permission, history },
	};
};

export const editPermissionsSuccess = (message) => {
	return {
		type: EDIT_PERMISSION_SUCCESS,
		payload: message,
	};
};

export const editPermissionsError = (message) => {
	return {
		type: EDIT_PERMISSION_ERROR,
		payload: message,
	};
};
export const editPermissionsReset = () => {
	return {
		type: EDIT_PERMISSION_RESET,
	};
};
