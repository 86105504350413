import { create } from "../common/http.service";

const VIEW_SINGLE_TEMPLATE_URL = "/get-template";
const SHARE_SINGLE_TEMPLATE_URL = "/get-template-public";
const TEMPLATE_SUCCESS_URL = "/submit-template-feedback";
const CHANNEL_DETAILS_URL = "/get-channel-details";
const VIEW_ALL_TEMPLATE_URL = "/list-template";
const VIEW_ALL_PUBLIC_TEMPLATE_URL = "/list-public-template";
const VIEW_ALL_TEMPLATE_STATS_URL = "/get-template-stats";
const CREATE_TEMPLATE_URL = "/create-template";
const UPDATE_TEMPLATE_URL = "/update-template";

export const viewSingleTemplateApi = async (payload) => {
  const res = await create(VIEW_SINGLE_TEMPLATE_URL, payload, true);

  return res;
};

export const shareSingleTemplateApi = async (payload) => {
  const res = await create(SHARE_SINGLE_TEMPLATE_URL, payload, false);

  return res;
};

export const getChannelDetailsApi = async (payload) => {
  const res = await create(CHANNEL_DETAILS_URL, payload, false);

  return res;
};

export const templateSuccessApi = async (payload) => {
  const res = await create(TEMPLATE_SUCCESS_URL, payload, false);

  return res;
};
export const viewAllTemplateApi = async (payload) => {
  const res = await create(VIEW_ALL_TEMPLATE_URL, payload, true);

  return res;
};

export const viewAllTemplatePublicApi = async (payload) => {
  const res = await create(VIEW_ALL_PUBLIC_TEMPLATE_URL, payload, true);

  return res;
};

export const viewAllTemplateStatsApi = async (payload) => {
  const res = await create(VIEW_ALL_TEMPLATE_STATS_URL, payload, true);

  return res;
};

export const createTemplateApi = async (payload) => {
  const res = await create(CREATE_TEMPLATE_URL, payload, true);

  return res;
};

export const updateTemplateApi = async (payload) => {
  const res = await create(UPDATE_TEMPLATE_URL, payload, true);

  return res;
};
