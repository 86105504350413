import { call, put, takeEvery } from "redux-saga/effects";
import { quizSuccessApi } from "../../../../../services/quiz/view-quiz/viewQuizApi";
import { surveySuccessApi } from "../../../../../services/surveys/view-survey/viewSurveyApi";

// Login Redux States

import { assessmentSuccessError, assessmentSuccessSuccess } from "./actions";

import { ASSESSMENT_SUCCESS_REQUEST } from "./actionTypes";

function* successSurvey({ payload: surveyID }) {
  let isThisQuiz = window.location.pathname.includes("/ql/");
  try {
    const response = isThisQuiz
      ? yield call(quizSuccessApi, surveyID)
      : yield call(surveySuccessApi, surveyID);
    if (response && response.success) {
      yield put(assessmentSuccessSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(assessmentSuccessError(error));
  }
}

function* SurveySuccessSaga() {
  yield takeEvery(ASSESSMENT_SUCCESS_REQUEST, successSurvey);
}

export default SurveySuccessSaga;
