import { call, put, takeEvery } from "redux-saga/effects";
import {
  createChartApi,
  deleteChartApi,
  publicChartApi,
  singleChartApi,
  updateChartApi,
  viewChartApi,
} from "../../../../services/surveys/chart-dashboard/chartDashboardApi";

// Login Redux States

import {
  createChartDashboardError,
  createChartDashboardSuccess,
  deleteChartDashboardError,
  deleteChartDashboardSuccess,
  updateChartDashboardError,
  updateChartDashboardSuccess,
  viewChartDashboardError,
  viewChartDashboardSuccess,
  viewPublicDashboardSuccess,
  viewSingleChartDashboardError,
  viewSingleChartDashboardSuccess,
} from "./actions";

import {
  CREATE_CHART_DASHBOARD_REQUEST,
  DELETE_CHART_DASHBOARD_REQUEST,
  UPDATE_CHART_DASHBOARD_REQUEST,
  VIEW_CHART_DASHBOARD_REQUEST,
  VIEW_PUBLIC_DASHBOARD_REQUEST,
  VIEW_SINGLE_CHART_DASHBOARD_REQUEST,
} from "./actionTypes";

function* getAllChartDashboards({ payload: surveyPayload }) {
  try {
    const response = yield call(viewChartApi, surveyPayload);
    if (response && response.success) {
      yield put(viewChartDashboardSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewChartDashboardError(error));
  }
}
function* getSingleChartDashboard({ payload: surveyPayload }) {
  try {
    const response = yield call(singleChartApi, surveyPayload);
    if (response && response.success) {
      yield put(viewSingleChartDashboardSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewSingleChartDashboardError(error));
  }
}
function* createChartDashboard({ payload: surveyPayload }) {
  try {
    const response = yield call(createChartApi, surveyPayload);
    if (response && response.success) {
      yield put(createChartDashboardSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(createChartDashboardError(error));
  }
}

function* deleteChartDashboard({ payload: surveyPayload }) {
  try {
    const response = yield call(deleteChartApi, surveyPayload);
    if (response && response.success) {
      yield put(deleteChartDashboardSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(deleteChartDashboardError(error));
  }
}
function* updateChartDashboard({ payload: surveyPayload }) {
  try {
    const response = yield call(updateChartApi, surveyPayload);
    if (response && response.success) {
      yield put(updateChartDashboardSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(updateChartDashboardError(error));
  }
}

function* getPublicDashboard({ payload: surveyPayload }) {
  try {
    const response = yield call(publicChartApi, surveyPayload);
    if (response && response.success) {
      yield put(viewPublicDashboardSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewSingleChartDashboardError(error));
  }
}

function* chartDashboardsSaga() {
  yield takeEvery(VIEW_CHART_DASHBOARD_REQUEST, getAllChartDashboards);
  yield takeEvery(VIEW_SINGLE_CHART_DASHBOARD_REQUEST, getSingleChartDashboard);
  yield takeEvery(VIEW_PUBLIC_DASHBOARD_REQUEST, getPublicDashboard);
  yield takeEvery(CREATE_CHART_DASHBOARD_REQUEST, createChartDashboard);
  yield takeEvery(DELETE_CHART_DASHBOARD_REQUEST, deleteChartDashboard);
  yield takeEvery(UPDATE_CHART_DASHBOARD_REQUEST, updateChartDashboard);
}

export default chartDashboardsSaga;
