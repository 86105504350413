import {
  GET_SINGLE_ASSESSMENT_ANSWERS,
  SINGLE_ASSESSMENT_ANSWERS_RESET,
  VIEW_SINGLE_ASSESSMENT_ERROR,
  VIEW_SINGLE_ASSESSMENT_REQUEST,
  VIEW_SINGLE_ASSESSMENT_RESET,
  VIEW_SINGLE_ASSESSMENT_SUCCESS,
  ADD_NEW_ASSESSMENT_USER,
  UPDATE_ASSESSMENT_USER,
  RESET_ASSESSMENT_USER,
} from "./actionTypes";

// Invite Member with email
export const viewSingleAssessmentRequest = (assessmentPayload) => {
  return {
    type: VIEW_SINGLE_ASSESSMENT_REQUEST,
    payload: assessmentPayload,
  };
};

export const viewSingleAssessmentSuccess = (message) => {
  return {
    type: VIEW_SINGLE_ASSESSMENT_SUCCESS,
    payload: message,
  };
};

export const viewSingleAssessmentError = (message) => {
  return {
    type: VIEW_SINGLE_ASSESSMENT_ERROR,
    payload: message,
  };
};

export const viewSingleAssessmentReset = () => {
  return {
    type: VIEW_SINGLE_ASSESSMENT_RESET,
  };
};

export const updateAssessmentAnswers = (assessmentPayload) => {
  return {
    type: GET_SINGLE_ASSESSMENT_ANSWERS,
    payload: assessmentPayload,
  };
};

export const assessmentAnswersReset = () => {
  return {
    type: SINGLE_ASSESSMENT_ANSWERS_RESET,
  };
};

export const addNewAssessmentUser = (payload) => {
  return {
    type: ADD_NEW_ASSESSMENT_USER,
    payload,
  };
};
export const updateAssessmentUser = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_USER,
    payload,
  };
};
export const resetAssessmentUser = () => {
  return {
    type: RESET_ASSESSMENT_USER,
  };
};
