import { call, put, takeEvery } from "redux-saga/effects";
import {
  createQuestionBankApi,
  getCompleteQuestionBankApi,
  getOneQuestionBankApi,
  searchFromQuestionBankApi,
} from "../../../../services/surveys/question-bank/questionBankApi";

// Login Redux States

import {
  createQuestionBankError,
  createQuestionBankSuccess,
  getAllQuestionBankError,
  getAllQuestionBankSuccess,
  getOneQuestionBankError,
  getOneQuestionBankSuccess,
  searchFromQuestionBankError,
  searchFromQuestionBankSuccess,
} from "./actions";

import {
  CREATE_QUESTION_BANK_REQUEST,
  GET_ALL_QUESTION_BANK_REQUEST,
  GET_ONE_QUESTION_BANK_REQUEST,
  SEARCH_QUESTION_BANK_REQUEST,
} from "./actionTypes";

function* addToQuestionBank({ payload: surveyPayload }) {
  try {
    const response = yield call(createQuestionBankApi, surveyPayload);

    if (response && response.success) {
      yield put(createQuestionBankSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(createQuestionBankError(error));
  }
}
function* getListQuestionBank({ payload: surveyPayload }) {
  try {
    const response = yield call(getCompleteQuestionBankApi, surveyPayload);

    if (response && response.success) {
      yield put(getAllQuestionBankSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getAllQuestionBankError(error));
  }
}

function* getOneQuestionBank({ payload: surveyPayload }) {
  try {
    const response = yield call(getOneQuestionBankApi, surveyPayload);

    if (response && response.success) {
      yield put(getOneQuestionBankSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getOneQuestionBankError(error));
  }
}

function* searchingQuestionBank({ payload: surveyPayload }) {
  try {
    const response = yield call(searchFromQuestionBankApi, surveyPayload);

    if (response && response.success) {
      yield put(searchFromQuestionBankSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(searchFromQuestionBankError(error));
  }
}

// export function* watchUpdateSurvey() {
// 	yield takeEvery(UPDATE_SURVEY_REQUEST, updateSurvey);
// }

function* questionBankSaga() {
  yield takeEvery(CREATE_QUESTION_BANK_REQUEST, addToQuestionBank);
  yield takeEvery(GET_ALL_QUESTION_BANK_REQUEST, getListQuestionBank);
  yield takeEvery(GET_ONE_QUESTION_BANK_REQUEST, getOneQuestionBank);
  yield takeEvery(SEARCH_QUESTION_BANK_REQUEST, searchingQuestionBank);
}

export default questionBankSaga;
