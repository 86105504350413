import { call, put, takeEvery } from "redux-saga/effects";
import { feedbackAPI } from "../../../services/auth/feedbackApi";
import { ADD_FEEDBACK_REQUEST } from "./actionTypes";
import { addFeedbackError, addFeedbackSuccess } from "./actions";

//Include Both Helper File with needed methods

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* addFeedback({ payload: message }) {
  try {
    const response = yield call(feedbackAPI, message);
    if (response && response.success) {
      yield put(addFeedbackSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addFeedbackError(error));
  }
}

function* addFeedbackSaga() {
  yield takeEvery(ADD_FEEDBACK_REQUEST, addFeedback);
}

export default addFeedbackSaga;
