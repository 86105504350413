import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	activeMemberError,
	activeMemberSuccess,
	addMemberEmailError,
	addMemberEmailSuccess,
	addMemberError,
	addMemberReset,
	addMemberSuccess,
	editMemberError,
	editMemberReset,
	editMemberSuccess,
	getMemberError,
	getMemberSuccess,
	getVerifiedMemberError,
	getVerifiedMemberSuccess,
} from './actions';
import {
	ACTIVE_MEMBER_REQUEST,
	ADD_MEMBER_EMAIL_REQUEST,
	ADD_MEMBER_REQUEST,
	EDIT_MEMBER_REQUEST,
	GET_MEMBER_REQUEST,
	GET_VERIFIED_MEMBER_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

import { editPermissionApi } from '../../services/auth/permission/permissionApi.js';
import {
	activeMemberApi,
	addMemberApi,
	getMemberApi,
	getVerifiedMemberApi,
	inviteMemberApi,
} from '../../services/team-members/membersApi';

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addMemberEmail({ payload: { email, history } }) {
	try {
		const response = yield call(inviteMemberApi, email);
		if (response && response.success) {
			yield put(addMemberEmailSuccess(response));
			history.push('/team-management');
		} else {
			throw response;
			// history.push('/team-management');
		}
	} catch (error) {
		yield put(addMemberEmailError(error));
	}
}
function* addMember({ payload: { member } }) {
	try {
		const response = yield call(addMemberApi, member);
		if (response && response.success) {
			yield put(addMemberSuccess(response));
			yield put(addMemberReset());
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addMemberError(error));
	}
}

function* activeMember({ payload: { member } }) {
	try {
		const response = yield call(activeMemberApi, member);
		if (response && response.success) {
			yield put(activeMemberSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(activeMemberError(error));
	}
}
function* getMember() {
	try {
		const response = yield call(getMemberApi);
		if (response && response.success) {
			yield put(getMemberSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getMemberError(error));
	}
}
function* getVerifiedMember() {
	try {
		const response = yield call(getVerifiedMemberApi);
		if (response && response.success) {
			yield put(getVerifiedMemberSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getVerifiedMemberError(error));
	}
}
function* editMember({ payload: { member, history } }) {
	try {
		const response = yield call(editPermissionApi, member);
		if (response && response.success) {
			yield put(editMemberSuccess(response));
			yield put(editMemberReset());
			history.push('/members');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editMemberError(error));
	}
}

function* membersSaga() {
	yield takeEvery(ADD_MEMBER_EMAIL_REQUEST, addMemberEmail);
	yield takeEvery(ADD_MEMBER_REQUEST, addMember);
	yield takeEvery(ACTIVE_MEMBER_REQUEST, activeMember);
	yield takeEvery(GET_MEMBER_REQUEST, getMember);
	yield takeEvery(GET_VERIFIED_MEMBER_REQUEST, getVerifiedMember);
	yield takeEvery(EDIT_MEMBER_REQUEST, editMember);
}

export default membersSaga;
