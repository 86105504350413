import {
	ADD_USER_ROLE_ERROR,
	ADD_USER_ROLE_REQUEST,
	ADD_USER_ROLE_RESET,
	ADD_USER_ROLE_SUCCESS,
	DELETE_USER_ROLE_ERROR,
	DELETE_USER_ROLE_REQUEST,
	DELETE_USER_ROLE_SUCCESS,
	EDIT_USER_ROLE_ERROR,
	EDIT_USER_ROLE_REQUEST,
	EDIT_USER_ROLE_RESET,
	EDIT_USER_ROLE_SUCCESS,
	GET_USER_ROLE_ERROR,
	GET_USER_ROLE_REQUEST,
	GET_USER_ROLE_RESET,
	GET_USER_ROLE_SUCCESS,
} from './actionTypes';

// Create UserRole
export const addUserRoleRequest = (user) => {
	return {
		type: ADD_USER_ROLE_REQUEST,
		payload: user,
	};
};

export const addUserRoleSuccess = (message) => {
	return {
		type: ADD_USER_ROLE_SUCCESS,
		payload: message,
	};
};

export const addUserRoleError = (message) => {
	return {
		type: ADD_USER_ROLE_ERROR,
		payload: message,
	};
};

export const addUserRoleReset = () => {
	return {
		type: ADD_USER_ROLE_RESET,
	};
};

// Get UserRole
export const getUserRoleRequest = () => {
	return {
		type: GET_USER_ROLE_REQUEST,
	};
};

export const getUserRoleSuccess = (message) => {
	return {
		type: GET_USER_ROLE_SUCCESS,
		payload: message,
	};
};

export const getUserRoleError = (message) => {
	return {
		type: GET_USER_ROLE_ERROR,
		payload: message,
	};
};

export const getUserRoleReset = (message) => {
	return {
		type: GET_USER_ROLE_RESET,
	};
};

// Delete UserRole
export const deleteUserRoleRequest = (role) => {
	return {
		type: DELETE_USER_ROLE_REQUEST,
		payload: role,
	};
};

export const deleteUserRoleSuccess = (message) => {
	return {
		type: DELETE_USER_ROLE_SUCCESS,
		payload: message,
	};
};

export const deleteUserRoleError = (message) => {
	return {
		type: DELETE_USER_ROLE_ERROR,
		payload: message,
	};
};

// Delete UserRole
export const editUserRoleRequest = (role, history) => {
	return {
		type: EDIT_USER_ROLE_REQUEST,
		payload: { role, history },
	};
};

export const editUserRoleSuccess = (message) => {
	return {
		type: EDIT_USER_ROLE_SUCCESS,
		payload: message,
	};
};

export const editUserRoleError = (message) => {
	return {
		type: EDIT_USER_ROLE_ERROR,
		payload: message,
	};
};
export const editUserRoleReset = () => {
	return {
		type: EDIT_USER_ROLE_RESET,
	};
};
