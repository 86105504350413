import {
	API_ERROR,
	LOGIN_RESET,
	LOGIN_SUCCESS,
	LOGIN_USER,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	SOCIAL_LOGIN,
} from './actionTypes';

export const loginUser = ({ values, history }) => {
	return {
		type: LOGIN_USER,
		payload: { values, history },
	};
};

export const loginSuccess = (user) => {
	return {
		type: LOGIN_SUCCESS,
		payload: user,
	};
};
export const loginReset = () => {
	return {
		type: LOGIN_RESET,
	};
};

export const logoutUser = (history) => {
	return {
		type: LOGOUT_USER,
		payload: { history },
	};
};

export const logoutUserSuccess = () => {
	return {
		type: LOGOUT_USER_SUCCESS,
		payload: {},
	};
};

export const apiError = (error) => {
	return {
		type: API_ERROR,
		payload: error,
	};
};

export const socialLogin = (data, history, type) => {
	return {
		type: SOCIAL_LOGIN,
		payload: { data, history, type },
	};
};
