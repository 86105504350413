import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import { viewAllQuizApi } from "../../../../services/quiz/view-quiz/viewQuizApi";
import {
  viewAllSurveyApi,
  viewAllSurveyStatsApi,
} from "../../../../services/surveys/view-survey/viewSurveyApi";
import { viewAllTemplateApi } from "../../../../services/template/templateApis";
import {
  VIEW_ALL_ASSESSMENT_REQUEST,
  VIEW_ALL_ASSESSMENT_STATS_REQUEST,
} from "./actionTypes";
import {
  viewAllAssessmentError,
  viewAllAssessmentStatsError,
  viewAllAssessmentStatsSuccess,
  viewAllAssessmentSuccess,
} from "./actions";

function* viewAllAssessment({ payload: { viewType } }) {
  try {
    const response =
      viewType == 1
        ? yield call(viewAllSurveyApi)
        : viewType == 2
        ? yield call(viewAllTemplateApi)
        : yield call(viewAllQuizApi);
    if (response && response.success) {
      let { data, ...rest } = response;
      data = data.map((item) => {
        const {
          pre_survey_questionnaire,
          survey_questionnaire,
          pre_quiz_questionnaire,
          quiz_questionnaire,
          ...rest
        } = item;

        return {
          ...rest,
          pre_survey_questionnaire:
            pre_survey_questionnaire || pre_quiz_questionnaire,
          survey_questionnaire:
            survey_questionnaire || quiz_questionnaire || [],
        };
      });

      yield put(viewAllAssessmentSuccess({ ...rest, data, viewType }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewAllAssessmentError(error));
  }
}
function* viewAllAssessmentStats() {
  try {
    const response = yield call(viewAllSurveyStatsApi);
    if (response && response.success) {
      yield put(viewAllAssessmentStatsSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewAllAssessmentStatsError(error));
  }
}

function* viewAllAssessmentSaga() {
  yield takeEvery(VIEW_ALL_ASSESSMENT_REQUEST, viewAllAssessment);
  yield takeEvery(VIEW_ALL_ASSESSMENT_STATS_REQUEST, viewAllAssessmentStats);
}

export default viewAllAssessmentSaga;
