import {
	ADD_COMPANY_LOGO_ERROR,
	ADD_COMPANY_LOGO_REQUEST,
	ADD_COMPANY_LOGO_RESET,
	ADD_COMPANY_LOGO_SUCCESS,
	ADD_INTRO_BG_ERROR,
	ADD_INTRO_BG_REQUEST,
	ADD_INTRO_BG_RESET,
	ADD_INTRO_BG_SUCCESS,
} from './actionTypes';

// Invite Member with email
export const addCompanyLogoRequest = (imgPayload) => {
	return {
		type: ADD_COMPANY_LOGO_REQUEST,
		payload: imgPayload,
	};
};

export const addCompanyLogoSuccess = (message) => {
	return {
		type: ADD_COMPANY_LOGO_SUCCESS,
		payload: message,
	};
};

export const addCompanyLogoError = (message) => {
	return {
		type: ADD_COMPANY_LOGO_ERROR,
		payload: message,
	};
};

export const addCompanyLogoReset = () => {
	return {
		type: ADD_COMPANY_LOGO_RESET,
	};
};

export const addIntroBGRequest = (imgPayload) => {
	return {
		type: ADD_INTRO_BG_REQUEST,
		payload: imgPayload,
	};
};

export const addIntroBGSuccess = (message) => {
	return {
		type: ADD_INTRO_BG_SUCCESS,
		payload: message,
	};
};

export const addIntroBGError = (message) => {
	return {
		type: ADD_INTRO_BG_ERROR,
		payload: message,
	};
};

export const addIntroBGReset = () => {
	return {
		type: ADD_INTRO_BG_RESET,
	};
};
