export const ADD_PERMISSION_REQUEST = 'ADD_PERMISSION_REQUEST';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_ERROR = 'ADD_PERMISSION_ERROR';
export const ADD_PERMISSION_RESET = 'ADD_PERMISSION_RESET';

export const GET_PERMISSION_REQUEST = 'GET_PERMISSION_REQUEST';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_ERROR = 'GET_PERMISSION_ERROR';
export const GET_PERMISSION_RESET = 'GET_PERMISSION_RESET';

export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_ERROR = 'DELETE_PERMISSION_ERROR';
export const DELETE_PERMISSION_RESET = 'DELETE_PERMISSION_RESET';

export const EDIT_PERMISSION_REQUEST = 'EDIT_PERMISSION_REQUEST';
export const EDIT_PERMISSION_SUCCESS = 'EDIT_PERMISSION_SUCCESS';
export const EDIT_PERMISSION_ERROR = 'EDIT_PERMISSION_ERROR';
export const EDIT_PERMISSION_RESET = 'EDIT_PERMISSION_RESET';
