import { call, put, takeEvery } from "redux-saga/effects";
import { surveyAnalysisApi } from "../../../../../services/surveys/survey-analysis/surveyAnalysisApi";

// Login Redux States

import {
  getAssessmentAnalysisError,
  getAssessmentAnalysisSuccess,
} from "./actions";

import { quizAnalysisApi } from "../../../../../services/quiz/quiz-analysis/quizAnalysisApi";
import { GET_ASSESSMENT_ANALYSIS_REQUEST } from "./actionTypes";

function* getAnalysisOfAssessment({ payload: surveyPayload }) {
  let isThisQuiz = window.location.pathname.includes("quiz");
  try {
    const response = isThisQuiz
      ? yield call(quizAnalysisApi, {
          ...surveyPayload,
          quiz_id: surveyPayload.survey_id,
        })
      : yield call(surveyAnalysisApi, surveyPayload);
    if (response && response.success) {
      yield put(getAssessmentAnalysisSuccess(response));
      // yield call(getAnalysisOfAssessmentAll, {
      // 	payloadAll: { ...surveyPayload, request_type: 2 },
      // });
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getAssessmentAnalysisError(error));
  }
}

function* assessmentAnalysisSaga() {
  yield takeEvery(GET_ASSESSMENT_ANALYSIS_REQUEST, getAnalysisOfAssessment);
}

export default assessmentAnalysisSaga;
