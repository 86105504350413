import { nanoid } from "nanoid";

export const loadingMessage = {
  id: nanoid(),
  roomId: 1,
  sender: "Lisa Parker",
  message: "loading",
  contentID: 0,
  createdAt: new Date(),
};

export const infoMessage = {
  id: nanoid(),
  roomId: 1,
  sender: "Lisa Parker",
  message: "",
  contentID: 1,
  createdAt: new Date(),
};

export const startMessage = {
  id: nanoid(),
  roomId: 1,
  sender: "New User",
  message: { value: "Start" },
  contentID: 1.1,
  createdAt: new Date(),
};

export const preQuestionMessage = (params) => ({
  id: nanoid(),
  roomId: 1,
  sender: "Lisa Parker",
  message: params,
  contentID: 2,
  createdAt: new Date(),
});

export const questionMessage = (params) => ({
  id: nanoid(),
  roomId: 1,
  sender: "Lisa Parker",
  message: params,
  contentID: 3,
  createdAt: new Date(),
});

export const responseMessage = (roomId, sender, curMessage) => ({
  id: nanoid(),
  roomId,
  sender,
  contentID: 4,
  message: { value: curMessage },
  createdAt: new Date(),
});
export const endMessage = (roomId = 1, sender = 1, curMessage = "Thanks") => ({
  id: nanoid(),
  roomId,
  sender: "Lisa Parker",
  contentID: 5,
  message: { value: curMessage },
  createdAt: new Date(),
});
