import {
	GET_AI_QUESTIONS_ERROR,
	GET_AI_QUESTIONS_REQUEST,
	GET_AI_QUESTIONS_RESET,
	GET_AI_QUESTIONS_SUCCESS,
} from './actionTypes';

// Survey Analysis
export const getAIQuestionsRequest = (question) => {
	return {
		type: GET_AI_QUESTIONS_REQUEST,
		payload: question,
	};
};

export const getAIQuestionsSuccess = (message) => {
	return {
		type: GET_AI_QUESTIONS_SUCCESS,
		payload: message,
	};
};

export const getAIQuestionsError = (message) => {
	return {
		type: GET_AI_QUESTIONS_ERROR,
		payload: message,
	};
};

export const getAIQuestionsReset = () => {
	return {
		type: GET_AI_QUESTIONS_RESET,
	};
};
