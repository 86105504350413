import { create, get } from '../../common/http.service';

const CREATE_USER_ROLE_URL = '/assign-user-role';
const GET_USER_ROLE_URL = '/get-user-role';
const UPDATE_USER_ROLE_URL = '/update-user-role';
const DELETE_USER_ROLE_URL = '/remove-user-role';

export const createUserRoleApi = async (payload) => {
	const res = await create(CREATE_USER_ROLE_URL, payload, true);

	return res;
};
export const getUserRoleApi = async (payload) => {
	const res = await get(GET_USER_ROLE_URL, payload, true);

	return res;
};
export const editUserRoleApi = async (payload) => {
	const res = await create(UPDATE_USER_ROLE_URL, payload, true);

	return res;
};
export const deleteUserRoleApi = async (payload) => {
	const res = await create(DELETE_USER_ROLE_URL, payload, true);

	return res;
};
