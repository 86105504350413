import {
	ADD_PERMISSION_ERROR,
	ADD_PERMISSION_REQUEST,
	ADD_PERMISSION_RESET,
	ADD_PERMISSION_SUCCESS,
	DELETE_PERMISSION_ERROR,
	DELETE_PERMISSION_REQUEST,
	DELETE_PERMISSION_SUCCESS,
	EDIT_PERMISSION_ERROR,
	EDIT_PERMISSION_REQUEST,
	EDIT_PERMISSION_RESET,
	EDIT_PERMISSION_SUCCESS,
	GET_PERMISSION_ERROR,
	GET_PERMISSION_REQUEST,
	GET_PERMISSION_RESET,
	GET_PERMISSION_SUCCESS,
} from './actionTypes';

const initialStateAddPermission = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetPermission = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeletePermission = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditPermission = {
	successData: null,
	error: null,
	loading: null,
};

const addPermission = (state = initialStateAddPermission, action) => {
	switch (action.type) {
		case ADD_PERMISSION_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_PERMISSION_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_PERMISSION_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_PERMISSION_RESET:
			state = { ...state, successData: null, error: null, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getPermission = (state = initialStateGetPermission, action) => {
	switch (action.type) {
		case GET_PERMISSION_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_PERMISSION_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_PERMISSION_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_PERMISSION_RESET:
			state = initialStateGetPermission;

			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deletePermission = (state = initialStateDeletePermission, action) => {
	switch (action.type) {
		case DELETE_PERMISSION_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_PERMISSION_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_PERMISSION_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editPermission = (state = initialStateEditPermission, action) => {
	switch (action.type) {
		case EDIT_PERMISSION_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_PERMISSION_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_PERMISSION_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_PERMISSION_RESET:
			state = { ...state, successData: null, error: null, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addPermission, deletePermission, getPermission, editPermission };
