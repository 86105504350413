import { ADD_COUNT_DOWN_TIME, ADD_COUNT_DOWN_TIME_RESET } from "./actionTypes";

const initialStateAddCriteria = "";

const countdownTime = (state = initialStateAddCriteria, action) => {
  switch (action.type) {
    case ADD_COUNT_DOWN_TIME:
      state = action.payload;
      break;

    case ADD_COUNT_DOWN_TIME_RESET:
      state = "";
      break;
    default:
      state;
      break;
  }
  return state;
};

export default countdownTime;
