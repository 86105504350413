export const ADD_ADMIN_EMAIL_REQUEST = 'ADD_ADMIN_EMAIL_REQUEST';
export const ADD_ADMIN_EMAIL_SUCCESS = 'ADD_ADMIN_EMAIL_SUCCESS';
export const ADD_ADMIN_EMAIL_ERROR = 'ADD_ADMIN_EMAIL_ERROR';
export const ADD_ADMIN_EMAIL_RESET = 'ADD_ADMIN_EMAIL_RESET';

export const GET_ADMIN_EMAIL_LIST_REQUEST = 'GET_ADMIN_EMAIL_LIST_REQUEST';
export const GET_ADMIN_EMAIL_LIST_SUCCESS = 'GET_ADMIN_EMAIL_LIST_SUCCESS';
export const GET_ADMIN_EMAIL_LIST_ERROR = 'GET_ADMIN_EMAIL_LIST_ERROR';
export const GET_ADMIN_EMAIL_LIST_RESET = 'GET_ADMIN_EMAIL_LIST_RESET';

export const DELETE_ADMIN_EMAIL_REQUEST = 'DELETE_ADMIN_EMAIL_REQUEST';
export const DELETE_ADMIN_EMAIL_SUCCESS = 'DELETE_ADMIN_EMAIL_SUCCESS';
export const DELETE_ADMIN_EMAIL_ERROR = 'DELETE_ADMIN_EMAIL_ERROR';
export const DELETE_ADMIN_EMAIL_RESET = 'DELETE_ADMIN_EMAIL_RESET';

export const EDIT_ADMIN_EMAIL_REQUEST = 'EDIT_ADMIN_EMAIL_REQUEST';
export const EDIT_ADMIN_EMAIL_SUCCESS = 'EDIT_ADMIN_EMAIL_SUCCESS';
export const EDIT_ADMIN_EMAIL_ERROR = 'EDIT_ADMIN_EMAIL_ERROR';
export const EDIT_ADMIN_EMAIL_RESET = 'EDIT_ADMIN_EMAIL_RESET';
