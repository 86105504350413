import { create } from "../../common/http.service";

const VIEW_SINGLE_SURVEY_URL = "/get-campaign";
const SHARE_SINGLE_SURVEY_URL = "/get-campaign-public";
const SURVEY_SUCCESS_URL = "/submit-campaign-feedback";

const CHANNEL_DETAILS_URL = "/get-channel-details";
const VIEW_ALL_SURVEY_URL = "/list-campaign";
const VIEW_ALL_SURVEY_STATS_URL = "/get-campaign-stats";
const VIEW_ALL_SURVEY_PUBLIC_URL = "/list-public-campaign";

export const viewSingleSurveyApi = async (payload) => {
  const res = await create(VIEW_SINGLE_SURVEY_URL, payload, true);

  return res;
};

export const shareSingleSurveyApi = async (payload) => {
  const res = await create(SHARE_SINGLE_SURVEY_URL, payload, false);

  return res;
};

export const getChannelDetailsApi = async (payload) => {
  const res = await create(CHANNEL_DETAILS_URL, payload, false);

  return res;
};

export const surveySuccessApi = async (payload) => {
  const res = await create(SURVEY_SUCCESS_URL, payload, false);
  return res;
};

export const viewAllSurveyApi = async (payload) => {
  const res = await create(VIEW_ALL_SURVEY_URL, payload, true);

  return res;
};
export const viewAllSurveyStatsApi = async (payload) => {
  const res = await create(VIEW_ALL_SURVEY_STATS_URL, payload, true);

  return res;
};
export const viewAllSurveyPublicApi = async (payload) => {
  const res = await create(VIEW_ALL_SURVEY_PUBLIC_URL, payload, true);

  return res;
};
