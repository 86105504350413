import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { defineLordIconElement } from "lord-icon-element";
import { loadAnimation } from "lottie-web";

import AOS from "aos";
import "aos/dist/aos.css";

import App from "./App";
import OverlayLoader from "./components/Common/OverlayLoader";
import "./index.css";
import createStore from "./store";
import { saveState } from "./store/localStorage";
import { saveStateToSession } from "./store/sessionStorage";
const { registerObserver } = require("react-perf-devtool");
registerObserver();
const initialState = {};

const configureStore = createStore(initialState, undefined);

AOS.init();

configureStore.subscribe(() => {
  saveState({
    profile: configureStore.getState().profile,
    viewAllAssessmentStats: configureStore.getState().viewAllAssessmentStats,
    assessmentUsers: configureStore.getState().assessmentUsers,
  });
  saveStateToSession({
    initiateAssessment: configureStore.getState().initiateAssessment,
  });
});

defineLordIconElement(loadAnimation);

ReactDOM.render(
  <Provider store={configureStore}>
    <Suspense fallback={<OverlayLoader />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
