import {
  ADD_CRITERIA_DUPE,
  ADD_CRITERIA_DUPE_RESET,
  ADD_CRITERIA_ERROR,
  ADD_CRITERIA_NON_DUPE,
  ADD_CRITERIA_NON_DUPE_RESET,
  ADD_CRITERIA_REQUEST,
  ADD_CRITERIA_RESET,
  ADD_CRITERIA_SUCCESS,
  DELETE_CRITERIA_ERROR,
  DELETE_CRITERIA_REQUEST,
  DELETE_CRITERIA_SUCCESS,
  EDIT_CRITERIA_ERROR,
  EDIT_CRITERIA_REQUEST,
  EDIT_CRITERIA_RESET,
  EDIT_CRITERIA_SUCCESS,
  GET_CRITERIA_ERROR,
  GET_CRITERIA_LIST_ERROR,
  GET_CRITERIA_LIST_REQUEST,
  GET_CRITERIA_LIST_RESET,
  GET_CRITERIA_LIST_SUCCESS,
  GET_CRITERIA_REQUEST,
  GET_CRITERIA_RESET,
  GET_CRITERIA_SUCCESS,
} from "./actionTypes";

// Create Criteria
export const addCriteriaRequest = (audience) => {
  return {
    type: ADD_CRITERIA_REQUEST,
    payload: audience,
  };
};

export const addCriteriaSuccess = (message) => {
  return {
    type: ADD_CRITERIA_SUCCESS,
    payload: message,
  };
};

export const addCriteriaError = (message) => {
  return {
    type: ADD_CRITERIA_ERROR,
    payload: message,
  };
};

export const addCriteriaReset = () => {
  return {
    type: ADD_CRITERIA_RESET,
  };
};

// Duplicate Criteria
export const addCriteriaDupes = (audience) => {
  return {
    type: ADD_CRITERIA_DUPE,
    payload: audience,
  };
};
export const addCriteriaDupesReset = () => {
  return {
    type: ADD_CRITERIA_DUPE_RESET,
  };
};

// Non-Duplicate Criteria
export const addCriteriaNonDupes = (audience) => {
  return {
    type: ADD_CRITERIA_NON_DUPE,
    payload: audience,
  };
};
export const addCriteriaNonDupesReset = () => {
  return {
    type: ADD_CRITERIA_NON_DUPE_RESET,
  };
};

// Get Criteria
export const getCriteriaRequest = (audience) => {
  return {
    type: GET_CRITERIA_REQUEST,
    payload: audience,
  };
};

export const getCriteriaSuccess = (message) => {
  return {
    type: GET_CRITERIA_SUCCESS,
    payload: message,
  };
};

export const getCriteriaError = (message) => {
  return {
    type: GET_CRITERIA_ERROR,
    payload: message,
  };
};

export const getCriteriaReset = (message) => {
  return {
    type: GET_CRITERIA_RESET,
  };
};
// Get Criteria lIst
export const getCriteriaListRequest = (audience) => {
  return {
    type: GET_CRITERIA_LIST_REQUEST,
    payload: audience,
  };
};

export const getCriteriaListSuccess = (message) => {
  return {
    type: GET_CRITERIA_LIST_SUCCESS,
    payload: message,
  };
};

export const getCriteriaListError = (message) => {
  return {
    type: GET_CRITERIA_LIST_ERROR,
    payload: message,
  };
};

export const getCriteriaListReset = (message) => {
  return {
    type: GET_CRITERIA_LIST_RESET,
  };
};

// Delete Criteria
export const deleteCriteriaRequest = (role) => {
  return {
    type: DELETE_CRITERIA_REQUEST,
    payload: role,
  };
};

export const deleteCriteriaSuccess = (message) => {
  return {
    type: DELETE_CRITERIA_SUCCESS,
    payload: message,
  };
};

export const deleteCriteriaError = (message) => {
  return {
    type: DELETE_CRITERIA_ERROR,
    payload: message,
  };
};

// Delete Criteria
export const editCriteriaRequest = (audience) => {
  return {
    type: EDIT_CRITERIA_REQUEST,
    payload: audience,
  };
};

export const editCriteriaSuccess = (message) => {
  return {
    type: EDIT_CRITERIA_SUCCESS,
    payload: message,
  };
};

export const editCriteriaError = (message) => {
  return {
    type: EDIT_CRITERIA_ERROR,
    payload: message,
  };
};
export const editCriteriaReset = () => {
  return {
    type: EDIT_CRITERIA_RESET,
  };
};
