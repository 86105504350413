import {
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  PROFILE_ERROR,
  PROFILE_IMAGE_ERROR,
  PROFILE_IMAGE_REQUEST,
  PROFILE_IMAGE_SUCCESS,
  PROFILE_REQUEST,
  PROFILE_RESET,
  PROFILE_SUCCESS,
  RESET_EDIT_PROFILE_FLAG,
  RESET_PROFILE_IMAGE_FLAG,
} from "./actionTypes";

// Get Profile
export const profileRequest = (history) => {
  return {
    type: PROFILE_REQUEST,
    payload: history,
  };
};
export const profileSuccess = (user) => {
  return {
    type: PROFILE_SUCCESS,
    payload: user,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const profileReset = () => {
  return {
    type: PROFILE_RESET,
  };
};

// Update Profile
export const editProfileRequest = (user) => {
  return {
    type: EDIT_PROFILE_REQUEST,
    payload: user,
  };
};
export const editProfileSuccess = (user) => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: user,
  };
};

export const editProfileError = (error) => {
  return {
    type: EDIT_PROFILE_ERROR,
    payload: error,
  };
};

export const editProfileReset = (error) => {
  return {
    type: RESET_EDIT_PROFILE_FLAG,
  };
};

// Profile Image
export const profileImageRequest = (history) => {
  return {
    type: PROFILE_IMAGE_REQUEST,
    payload: history,
  };
};
export const profileImageSuccess = (user) => {
  return {
    type: PROFILE_IMAGE_SUCCESS,
    payload: user,
  };
};

export const profileImageError = (error) => {
  return {
    type: PROFILE_IMAGE_ERROR,
    payload: error,
  };
};

export const resetProfileImageFlag = (error) => {
  return {
    type: RESET_PROFILE_IMAGE_FLAG,
  };
};

// Admin Emails
export const emailVerificationRequest = (urlToken) => {
  return {
    type: EMAIL_VERIFICATION_REQUEST,
    payload: urlToken,
  };
};
export const emailVerificationSuccess = (data) => {
  return {
    type: EMAIL_VERIFICATION_SUCCESS,
    payload: data,
  };
};
export const EMAIL_VERIFICATION_ERROR = (data) => {
  return {
    type: EMAIL_VERIFICATION_REQUEST,
    payload: data,
  };
};
