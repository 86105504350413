import {
  GET_ASSESSMENT_ANALYSIS_SUMMARY_ERROR,
  GET_ASSESSMENT_ANALYSIS_SUMMARY_REQUEST,
  GET_ASSESSMENT_ANALYSIS_SUMMARY_RESET,
  GET_ASSESSMENT_ANALYSIS_SUMMARY_SUCCESS,
} from "./actionTypes";

// Assessment Analysis Summary
export const getAssessmentAnalysisSummaryRequest = (assessmentPayload) => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_SUMMARY_REQUEST,
    payload: assessmentPayload,
  };
};

export const getAssessmentAnalysisSummarySuccess = (message) => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_SUMMARY_SUCCESS,
    payload: message,
  };
};

export const getAssessmentAnalysisSummaryError = (message) => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_SUMMARY_ERROR,
    payload: message,
  };
};

export const getAssessmentAnalysisSummaryReset = () => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_SUMMARY_RESET,
  };
};
