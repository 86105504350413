import {
	ADD_ADMIN_EMAIL_ERROR,
	ADD_ADMIN_EMAIL_REQUEST,
	ADD_ADMIN_EMAIL_RESET,
	ADD_ADMIN_EMAIL_SUCCESS,
	DELETE_ADMIN_EMAIL_ERROR,
	DELETE_ADMIN_EMAIL_REQUEST,
	DELETE_ADMIN_EMAIL_SUCCESS,
	EDIT_ADMIN_EMAIL_ERROR,
	EDIT_ADMIN_EMAIL_REQUEST,
	EDIT_ADMIN_EMAIL_RESET,
	EDIT_ADMIN_EMAIL_SUCCESS,
	GET_ADMIN_EMAIL_LIST_ERROR,
	GET_ADMIN_EMAIL_LIST_REQUEST,
	GET_ADMIN_EMAIL_LIST_RESET,
	GET_ADMIN_EMAIL_LIST_SUCCESS,
} from './actionTypes';

// Create AdminEmail
export const addAdminEmailRequest = (email) => {
	return {
		type: ADD_ADMIN_EMAIL_REQUEST,
		payload: email,
	};
};

export const addAdminEmailSuccess = (message) => {
	return {
		type: ADD_ADMIN_EMAIL_SUCCESS,
		payload: message,
	};
};

export const addAdminEmailError = (message) => {
	return {
		type: ADD_ADMIN_EMAIL_ERROR,
		payload: message,
	};
};

export const addAdminEmailReset = () => {
	return {
		type: ADD_ADMIN_EMAIL_RESET,
	};
};

// Get AdminEmail
export const getAdminEmailListRequest = (surveyID) => {
	return {
		type: GET_ADMIN_EMAIL_LIST_REQUEST,
		payload: surveyID,
	};
};

export const getAdminEmailListSuccess = (message) => {
	return {
		type: GET_ADMIN_EMAIL_LIST_SUCCESS,
		payload: message,
	};
};

export const getAdminEmailListError = (message) => {
	return {
		type: GET_ADMIN_EMAIL_LIST_ERROR,
		payload: message,
	};
};

export const getAdminEmailListReset = (message) => {
	return {
		type: GET_ADMIN_EMAIL_LIST_RESET,
	};
};

// Delete AdminEmail
export const deleteAdminEmailRequest = (role) => {
	return {
		type: DELETE_ADMIN_EMAIL_REQUEST,
		payload: role,
	};
};

export const deleteAdminEmailSuccess = (message) => {
	return {
		type: DELETE_ADMIN_EMAIL_SUCCESS,
		payload: message,
	};
};

export const deleteAdminEmailError = (message) => {
	return {
		type: DELETE_ADMIN_EMAIL_ERROR,
		payload: message,
	};
};

// Edit AdminEmail
export const editAdminEmailRequest = (attrData) => {
	return {
		type: EDIT_ADMIN_EMAIL_REQUEST,
		payload: attrData,
	};
};

export const editAdminEmailSuccess = (message) => {
	return {
		type: EDIT_ADMIN_EMAIL_SUCCESS,
		payload: message,
	};
};

export const editAdminEmailError = (message) => {
	return {
		type: EDIT_ADMIN_EMAIL_ERROR,
		payload: message,
	};
};
export const editAdminEmailReset = () => {
	return {
		type: EDIT_ADMIN_EMAIL_RESET,
	};
};
