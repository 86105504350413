import { create, get } from "../common/http.service";

const CREATE_MEMBER_EMAIL_URL = "/register-team";
const CREATE_MEMBER_URL = "/register";
const ACTIVE_MEMBER_URL = "/update-user-status";
const GET_MEMBER_URL = "/user-team";
const GET_VERIFIED_MEMBER_URL = "/users-list";
const UPDATE_MEMBER_URL = "/update-permission";

export const inviteMemberApi = async (payload) => {
  const res = await create(CREATE_MEMBER_EMAIL_URL, payload, true);

  return res;
};
export const addMemberApi = async (payload) => {
  const res = await create(CREATE_MEMBER_URL, payload, true);

  return res;
};

export const activeMemberApi = async (payload) => {
  const res = await create(ACTIVE_MEMBER_URL, payload, true);

  return res;
};
export const getMemberApi = async (payload) => {
  const res = await get(GET_MEMBER_URL, payload, true);

  return res;
};
export const getVerifiedMemberApi = async (payload) => {
  const res = await get(GET_VERIFIED_MEMBER_URL, payload, true);

  return res;
};
export const editMemberApi = async (payload) => {
  const res = await create(UPDATE_MEMBER_URL, payload, true);

  return res;
};
