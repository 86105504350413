import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";

export const AutoDismissAlert = ({
  color = "danger",
  message = "",
  resetDispatch,
  customClass = "",
  customStyle = {},
}) => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    let timeId;

    timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setAlert(false);
      dispatch(resetDispatch());
    }, 2500);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (!alert) {
    return null;
  }

  return (
    <Fragment>
      <Alert color={color} className={"" + customClass} style={customStyle}>
        {message}
      </Alert>
    </Fragment>
  );
};

AutoDismissAlert.propTypes = {
  color: PropTypes.string,
  customClass: PropTypes.string,
  customStyle: PropTypes.object,
  message: PropTypes.string,
  resetDispatch: PropTypes.func,
};
