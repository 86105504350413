import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { addImageApi } from "../../../../services/surveys/create-survey/createSurveysApi";
import { UPLOAD_IMAGE_REQUEST } from "./actionTypes";
import { uploadImageError, uploadImageSuccess } from "./actions";

function* uploadImage({ payload: imgPayload }) {
  try {
    const response = yield call(addImageApi, imgPayload);

    if (response && response.success) {
      yield put(uploadImageSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(uploadImageError(error));
  }
}

function* UploadImageSaga() {
  yield takeEvery(UPLOAD_IMAGE_REQUEST, uploadImage);
}

export default UploadImageSaga;
