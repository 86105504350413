import {
	CHANNEL_DETAILS_ERROR,
	CHANNEL_DETAILS_REQUEST,
	CHANNEL_DETAILS_RESET,
	CHANNEL_DETAILS_SUCCESS,
} from './actionTypes';

// Invite Member with email
export const channelDetailsRequest = (channelID) => {
	return {
		type: CHANNEL_DETAILS_REQUEST,
		payload: channelID,
	};
};

export const channelDetailsSuccess = (message) => {
	return {
		type: CHANNEL_DETAILS_SUCCESS,
		payload: message,
	};
};

export const channelDetailsError = (message) => {
	return {
		type: CHANNEL_DETAILS_ERROR,
		payload: message,
	};
};

export const channelDetailsReset = () => {
	return {
		type: CHANNEL_DETAILS_RESET,
	};
};
