import {
  GET_AI_QUESTIONS_ERROR,
  GET_AI_QUESTIONS_REQUEST,
  GET_AI_QUESTIONS_RESET,
  GET_AI_QUESTIONS_SUCCESS,
} from "./actionTypes";

const initialStateAI = {
  successData: null,
  error: null,
  loading: null,
};

const AIQuestions = (state = initialStateAI, action) => {
  switch (action.type) {
    case GET_AI_QUESTIONS_REQUEST:
      state = {
        error: null,
        successData: null,
        loading: true,
      };
      break;

    case GET_AI_QUESTIONS_SUCCESS:
      state = {
        error: null,
        successData: action.payload,
        loading: null,
      };
      break;
    case GET_AI_QUESTIONS_ERROR:
      state = { successData: null, error: action.payload, loading: false };
      break;
    case GET_AI_QUESTIONS_RESET:
      state = initialStateAI;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { AIQuestions };
