import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	getInfoCategoriesApi,
	searchInfoCategoriesApi,
} from '../../services/information/informationApi';
import {
	getInfoCategoriesError,
	getInfoCategoriesSuccess,
	searchInfoCategoriesError,
	searchInfoCategoriesSuccess,
} from './actions';
import {
	GET_INFO_CATEGORIES_REQUEST,
	SEARCH_INFO_CATEGORIES_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

function* getInfoCategories({ payload: info }) {
	try {
		const response = yield call(getInfoCategoriesApi, info);
		if (response && response.success) {
			yield put(getInfoCategoriesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getInfoCategoriesError(error));
	}
}
function* searchInfoCategories({ payload: info }) {
	try {
		const response = yield call(searchInfoCategoriesApi, info);
		if (response && response.success) {
			yield put(searchInfoCategoriesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(searchInfoCategoriesError(error));
	}
}

function* informationSaga() {
	yield takeEvery(GET_INFO_CATEGORIES_REQUEST, getInfoCategories);
	yield takeEvery(SEARCH_INFO_CATEGORIES_REQUEST, searchInfoCategories);
}

export default informationSaga;
