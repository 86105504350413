import {
	ADD_USER_PERMISSIONS_ERROR,
	ADD_USER_PERMISSIONS_REQUEST,
	ADD_USER_PERMISSIONS_RESET,
	ADD_USER_PERMISSIONS_SUCCESS,
	DELETE_USER_PERMISSIONS_ERROR,
	DELETE_USER_PERMISSIONS_REQUEST,
	DELETE_USER_PERMISSIONS_SUCCESS,
	EDIT_USER_PERMISSIONS_ERROR,
	EDIT_USER_PERMISSIONS_REQUEST,
	EDIT_USER_PERMISSIONS_RESET,
	EDIT_USER_PERMISSIONS_SUCCESS,
	GET_USER_PERMISSIONS_ERROR,
	GET_USER_PERMISSIONS_REQUEST,
	GET_USER_PERMISSIONS_RESET,
	GET_USER_PERMISSIONS_SUCCESS,
} from './actionTypes';

const initialStateAddUserPermissions = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetUserPermissions = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteUserPermissions = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditUserPermissions = {
	successData: null,
	error: null,
	loading: null,
};

const addUserPermissions = (state = initialStateAddUserPermissions, action) => {
	switch (action.type) {
		case ADD_USER_PERMISSIONS_REQUEST:
			state = {
				...state,
				loading: true,
			};
			break;
		case ADD_USER_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_USER_PERMISSIONS_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;
		case ADD_USER_PERMISSIONS_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getUserPermissions = (state = initialStateGetUserPermissions, action) => {
	switch (action.type) {
		case GET_USER_PERMISSIONS_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_USER_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_USER_PERMISSIONS_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;

		case GET_USER_PERMISSIONS_RESET:
			state = initialStateGetUserPermissions;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteUserPermissions = (
	state = initialStateDeleteUserPermissions,
	action
) => {
	switch (action.type) {
		case DELETE_USER_PERMISSIONS_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_USER_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_USER_PERMISSIONS_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editUserPermissions = (
	state = initialStateEditUserPermissions,
	action
) => {
	switch (action.type) {
		case EDIT_USER_PERMISSIONS_REQUEST:
			state = {
				...state,
				successData: {},
				loading: true,
			};
			break;
		case EDIT_USER_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_USER_PERMISSIONS_ERROR:
			state = {
				...state,
				successDataError: action.payload,
				loading: false,
			};
			break;
		case EDIT_USER_PERMISSIONS_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export {
	addUserPermissions,
	deleteUserPermissions,
	getUserPermissions,
	editUserPermissions,
};
