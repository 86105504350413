import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	createRolePermissionsApi,
	editRolePermissionsApi,
	getRolePermissionsApi,
} from '../../../services/auth/roleAPI/rolePermissionsApi';
import {
	addRolePermissionsError,
	addRolePermissionsSuccess,
	editRolePermissionsError,
	editRolePermissionsSuccess,
	getRolePermissionsError,
	getRolePermissionsSuccess,
} from './actions';
import {
	ADD_ROLE_PERMISSIONS_REQUEST,
	EDIT_ROLE_PERMISSIONS_REQUEST,
	GET_ROLE_PERMISSIONS_REQUEST,
} from './actionTypes';

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addRolePermissions({ payload: { user, history } }) {
	try {
		const response = yield call(createRolePermissionsApi, user);
		if (response && response.success) {
			yield put(addRolePermissionsSuccess(response));
			history.push('/view-roles');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addRolePermissionsError(error));
	}
}
function* getRolePermissions() {
	try {
		const response = yield call(getRolePermissionsApi);
		if (response && response.success) {
			yield put(getRolePermissionsSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getRolePermissionsError(error));
	}
}
function* editRolePermissions({ payload: { role, history } }) {
	try {
		const response = yield call(editRolePermissionsApi, role);
		if (response && response.success) {
			yield put(editRolePermissionsSuccess(response));
			history.push('/view-roles');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editRolePermissionsError(error));
	}
}

function* rolePermissionsSaga() {
	yield takeEvery(ADD_ROLE_PERMISSIONS_REQUEST, addRolePermissions);
	yield takeEvery(GET_ROLE_PERMISSIONS_REQUEST, getRolePermissions);
	yield takeEvery(EDIT_ROLE_PERMISSIONS_REQUEST, editRolePermissions);
}

export default rolePermissionsSaga;
