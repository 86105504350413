import { create } from "../common/http.service";

const CREATE_AUDIENCE_URL = "/create-quiz-grading-criteria";
const GET_AUDIENCE_URL = "/get-quiz-grading-criteria";
const GET_ALL_AUDIENCE_URL = "/get-quiz-grading-criteria-list";
const UPDATE_AUDIENCE_URL = "/update-quiz-grading-criteria";
const DELETE_AUDIENCE_URL = "/remove-people";

export const createCriteriaApi = async (payload) => {
  const res = await create(CREATE_AUDIENCE_URL, payload, true);

  return res;
};
export const getCriteriaApi = async (payload) => {
  const res = await create(GET_AUDIENCE_URL, payload, true);

  return res;
};
export const getCriteriaListApi = async (payload) => {
  const res = await create(GET_ALL_AUDIENCE_URL, payload, true);

  return res;
};
export const editCriteriaApi = async (payload) => {
  const res = await create(UPDATE_AUDIENCE_URL, payload, true);

  return res;
};
export const deleteCriteriaApi = async (payload) => {
  const res = await create(DELETE_AUDIENCE_URL, payload, true);

  return res;
};
