export const ADD_USER_PERMISSIONS_REQUEST = 'ADD_USER_PERMISSIONS_REQUEST';
export const ADD_USER_PERMISSIONS_SUCCESS = 'ADD_USER_PERMISSIONS_SUCCESS';
export const ADD_USER_PERMISSIONS_ERROR = 'ADD_USER_PERMISSIONS_ERROR';
export const ADD_USER_PERMISSIONS_RESET = 'ADD_USER_PERMISSIONS_RESET';

export const GET_USER_PERMISSIONS_REQUEST = 'GET_USER_PERMISSIONS_REQUEST';
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_ERROR = 'GET_USER_PERMISSIONS_ERROR';
export const GET_USER_PERMISSIONS_RESET = 'GET_USER_PERMISSIONS_RESET';

export const DELETE_USER_PERMISSIONS_REQUEST =
	'DELETE_USER_PERMISSIONS_REQUEST';
export const DELETE_USER_PERMISSIONS_SUCCESS =
	'DELETE_USER_PERMISSIONS_SUCCESS';
export const DELETE_USER_PERMISSIONS_ERROR = 'DELETE_USER_PERMISSIONS_ERROR';
export const DELETE_USER_PERMISSIONS_RESET = 'DELETE_USER_PERMISSIONS_RESET';

export const EDIT_USER_PERMISSIONS_REQUEST = 'EDIT_USER_PERMISSIONS_REQUEST';
export const EDIT_USER_PERMISSIONS_SUCCESS = 'EDIT_USER_PERMISSIONS_SUCCESS';
export const EDIT_USER_PERMISSIONS_ERROR = 'EDIT_USER_PERMISSIONS_ERROR';
export const EDIT_USER_PERMISSIONS_RESET = 'EDIT_USER_PERMISSIONS_RESET';
