import {
  GET_QUIZ_SUMMARY_ERROR,
  GET_QUIZ_SUMMARY_REQUEST,
  GET_QUIZ_SUMMARY_RESET,
  GET_QUIZ_SUMMARY_SUCCESS,
} from "./actionTypes";

// Quiz Analysis Summary
export const getQuizSummaryRequest = (quizPayload) => {
  return {
    type: GET_QUIZ_SUMMARY_REQUEST,
    payload: quizPayload,
  };
};

export const getQuizSummarySuccess = (message) => {
  return {
    type: GET_QUIZ_SUMMARY_SUCCESS,
    payload: message,
  };
};

export const getQuizSummaryError = (message) => {
  return {
    type: GET_QUIZ_SUMMARY_ERROR,
    payload: message,
  };
};

export const getQuizSummaryReset = () => {
  return {
    type: GET_QUIZ_SUMMARY_RESET,
  };
};
