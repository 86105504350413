import { create } from '../../common/http.service';

const GET_SURVEY_ANALYSIS_URL = '/get-campaign-feedback-list';
const GET_SURVEY_ANALYSIS_SUMMARY_URL = '/get-campaign-feedback-analysis-one';
const GET_QUESTION_SENTIMENTS_URL = '/get-question-sentiments';

export const surveyAnalysisApi = async (payload) => {
	const res = await create(GET_SURVEY_ANALYSIS_URL, payload, true);

	return res;
};
export const surveyAnalysisSummaryApi = async (payload) => {
	const res = await create(GET_SURVEY_ANALYSIS_SUMMARY_URL, payload, true);

	return res;
};

export const questionSentimentsAnalysisApi = async (payload) => {
	const res = await create(GET_QUESTION_SENTIMENTS_URL, payload, true);

	return res;
};
