import {
	ADD_CHANNEL_ERROR,
	ADD_CHANNEL_REQUEST,
	ADD_CHANNEL_RESET,
	ADD_CHANNEL_SUCCESS,
	DELETE_CHANNEL_ERROR,
	DELETE_CHANNEL_REQUEST,
	DELETE_CHANNEL_SUCCESS,
	GET_CHANNEL_ERROR,
	GET_CHANNEL_REQUEST,
	GET_CHANNEL_RESET,
	GET_CHANNEL_SUCCESS,
} from './actionTypes';

const initialStateAddChannel = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetChannel = {
	successData: null,
	error: null,
	loading: null,
};

const initialStateDeleteChannel = {
	successData: null,
	error: null,
	loading: null,
};

const addChannel = (state = initialStateAddChannel, action) => {
	switch (action.type) {
		case ADD_CHANNEL_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case ADD_CHANNEL_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_CHANNEL_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_CHANNEL_RESET:
			state = { ...state, successData: null, error: null, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getChannel = (state = initialStateGetChannel, action) => {
	switch (action.type) {
		case GET_CHANNEL_REQUEST:
			state = {
				...state,
				loading: true,
				error: null,
			};
			break;
		case GET_CHANNEL_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_CHANNEL_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_CHANNEL_RESET:
			state = initialStateGetChannel;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

const deleteChannel = (state = initialStateDeleteChannel, action) => {
	switch (action.type) {
		case DELETE_CHANNEL_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case DELETE_CHANNEL_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_CHANNEL_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addChannel, deleteChannel, getChannel };
