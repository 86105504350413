import {
  SHARE_SINGLE_SURVEY_ERROR,
  SHARE_SINGLE_SURVEY_REQUEST,
  SHARE_SINGLE_SURVEY_RESET,
  SHARE_SINGLE_SURVEY_SUCCESS,
} from "./actionTypes";

const initialStateshareSingleAssessment = {
  successData: null,
  error: null,
  loading: null,
};

const shareSingleAssessment = (
  state = initialStateshareSingleAssessment,
  action
) => {
  switch (action.type) {
    case SHARE_SINGLE_SURVEY_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;

    case SHARE_SINGLE_SURVEY_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case SHARE_SINGLE_SURVEY_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case SHARE_SINGLE_SURVEY_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { shareSingleAssessment };
