export const VIEW_SINGLE_ASSESSMENT_REQUEST = "VIEW_SINGLE_ASSESSMENT_REQUEST";
export const VIEW_SINGLE_ASSESSMENT_SUCCESS = "VIEW_SINGLE_ASSESSMENT_SUCCESS";
export const VIEW_SINGLE_ASSESSMENT_ERROR = "VIEW_SINGLE_ASSESSMENT_ERROR";
export const VIEW_SINGLE_ASSESSMENT_RESET = "VIEW_SINGLE_ASSESSMENT_RESET";

export const GET_SINGLE_ASSESSMENT_ANSWERS = "GET_SINGLE_ASSESSMENT_ANSWERS";
export const SINGLE_ASSESSMENT_ANSWERS_RESET =
  "SINGLE_ASSESSMENT_ANSWERS_RESET";
export const ADD_NEW_ASSESSMENT_USER = "ADD_NEW_ASSESSMENT_USER";
export const UPDATE_ASSESSMENT_USER = "UPDATE_ASSESSMENT_USER";
export const RESET_ASSESSMENT_USER = "RESET_ASSESSMENT_USER";

