import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { updateSurveyApi } from "../../../../services/surveys/update-survey/updateSurveyApi";

// Login Redux States

import { updateAssessmentError, updateAssessmentSuccess } from "./actions";

import { updateQuizApi } from "../../../../services/quiz/update-quiz/updateQuizApi";
import { updateTemplateApi } from "../../../../services/template/templateApis";
import { UPDATE_ASSESSMENT_REQUEST } from "./actionTypes";

function* updateAssessment({ payload: surveyPayload }) {
  let view_type = JSON.parse(localStorage.getItem("view_type"));
  let isThisQuiz =
    window.location.pathname.includes("quiz") || view_type?.value == "quiz";

  try {
    const response = isThisQuiz
      ? yield call(updateQuizApi, surveyPayload)
      : surveyPayload.is_template
      ? yield call(updateTemplateApi, surveyPayload)
      : yield call(updateSurveyApi, surveyPayload);

    if (response && response.success) {
      yield put(updateAssessmentSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(updateAssessmentError(error));
  }
}

export function* watchUpdateAssessment() {
  yield takeEvery(UPDATE_ASSESSMENT_REQUEST, updateAssessment);
}

function* updateAssessmentSaga() {
  yield all([fork(watchUpdateAssessment)]);
}

export default updateAssessmentSaga;
