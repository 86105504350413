import {
	ADD_ROLE_ERROR,
	ADD_ROLE_REQUEST,
	ADD_ROLE_RESET,
	ADD_ROLE_SUCCESS,
	DELETE_ROLE_ERROR,
	DELETE_ROLE_REQUEST,
	DELETE_ROLE_SUCCESS,
	EDIT_ROLE_ERROR,
	EDIT_ROLE_REQUEST,
	EDIT_ROLE_RESET,
	EDIT_ROLE_SUCCESS,
	GET_ROLE_ERROR,
	GET_ROLE_REQUEST,
	GET_ROLE_RESET,
	GET_ROLE_SUCCESS,
} from './actionTypes';

// Create Role
export const addRoleRequest = ({ role, history }) => {
	return {
		type: ADD_ROLE_REQUEST,
		payload: { role, history },
	};
};

export const addRoleSuccess = (message) => {
	return {
		type: ADD_ROLE_SUCCESS,
		payload: message,
	};
};

export const addRoleError = (message) => {
	return {
		type: ADD_ROLE_ERROR,
		payload: message,
	};
};

export const addRoleReset = () => {
	return {
		type: ADD_ROLE_RESET,
	};
};

// Get Role
export const getRoleRequest = () => {
	return {
		type: GET_ROLE_REQUEST,
	};
};

export const getRoleSuccess = (message) => {
	return {
		type: GET_ROLE_SUCCESS,
		payload: message,
	};
};

export const getRoleError = (message) => {
	return {
		type: GET_ROLE_ERROR,
		payload: message,
	};
};

export const getRoleReset = (message) => {
	return {
		type: GET_ROLE_RESET,
	};
};

// Delete Role
export const deleteRoleRequest = (role, history) => {
	return {
		type: DELETE_ROLE_REQUEST,
		payload: { role, history },
	};
};

export const deleteRoleSuccess = (message) => {
	return {
		type: DELETE_ROLE_SUCCESS,
		payload: message,
	};
};

export const deleteRoleError = (message) => {
	return {
		type: DELETE_ROLE_ERROR,
		payload: message,
	};
};

// Delete Role
export const editRoleRequest = ({ role, history }) => {
	return {
		type: EDIT_ROLE_REQUEST,
		payload: { role, history },
	};
};

export const editRoleSuccess = (message) => {
	return {
		type: EDIT_ROLE_SUCCESS,
		payload: message,
	};
};

export const editRoleError = (message) => {
	return {
		type: EDIT_ROLE_ERROR,
		payload: message,
	};
};
export const editRoleReset = () => {
	return {
		type: EDIT_ROLE_RESET,
	};
};
