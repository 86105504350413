import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import { userForgetPasswordError, userForgetPasswordSuccess } from './actions';
import { FORGET_PASSWORD } from './actionTypes';

//Include Both Helper File with needed methods

import { forgotPasswordApi } from '../../../services/auth/forgetPasswordApi';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user } }) {
	try {
		const response = yield call(forgotPasswordApi, user);

		if (response && response.success) {
			yield put(userForgetPasswordSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(userForgetPasswordError(error));
	}
}

export function* watchUserPasswordForget() {
	yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
	yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
