import {
  ADD_SEGMENT_ERROR,
  ADD_SEGMENT_REQUEST,
  ADD_SEGMENT_RESET,
  ADD_SEGMENT_SUCCESS,
  GET_SEGMENTS_ERROR,
  GET_SEGMENTS_REQUEST,
  GET_SEGMENTS_RESET,
  GET_SEGMENTS_SUCCESS,
} from "./actionTypes";

const initialStateSegment = {
  successData: null,
  error: null,
  loading: null,
};

const initialStateGetSegments = {
  successData: null,
  error: null,
  loading: null,
};

const addSegmentAssessment = (state = initialStateSegment, action) => {
  switch (action.type) {
    case ADD_SEGMENT_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;
    case ADD_SEGMENT_SUCCESS:
      state = {
        ...state,
        error: null,
        successData: action.payload,
        loading: false,
      };
      break;
    case ADD_SEGMENT_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case ADD_SEGMENT_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const getSegmentsAssessment = (state = initialStateGetSegments, action) => {
  switch (action.type) {
    case GET_SEGMENTS_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;
    case GET_SEGMENTS_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case GET_SEGMENTS_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SEGMENTS_RESET:
      state = initialStateGetSegments;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { addSegmentAssessment, getSegmentsAssessment };
