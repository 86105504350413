import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { addImageApi } from "../../../../../services/surveys/create-survey/createSurveysApi";
import { addDisBGError, addDisBGSuccess } from "./actions";
import { ADD_DIS_BG_REQUEST } from "./actionTypes";

function* addDisBG({ payload: imgPayload }) {
  try {
    const response = yield call(addImageApi, imgPayload);

    if (response && response.success) {
      yield put(addDisBGSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addDisBGError(error));
  }
}

function* disqualificationSaga() {
  yield takeEvery(ADD_DIS_BG_REQUEST, addDisBG);
}

export default disqualificationSaga;
