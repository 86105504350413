import {
	RESET_PASSWORD,
	RESET_PASSWORD_ERROR,
	RESET_PASSWORD_SUCCESS,
} from './actionTypes';

const initialState = {
	successData: null,
	error: null,
	loading: false,
};

const resetPassword = (state = initialState, action) => {
	switch (action.type) {
		case RESET_PASSWORD:
			state = {
				...state,
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case RESET_PASSWORD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case RESET_PASSWORD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default resetPassword;
