import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_RESET,
  CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes";

export const changePasswordRequest = (user, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { user, history },
  };
};

export const changePasswordSuccess = (message) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const changePasswordError = (message) => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: message,
  };
};

export const changePasswordReset = () => {
  return {
    type: CHANGE_PASSWORD_RESET,
  };
};
