import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import {
  getQuestionSentimentsError,
  getQuestionSentimentsSuccess,
} from "./actions";

import { questionSentimentsAnalysisApi } from "../../../../../../services/surveys/survey-analysis/surveyAnalysisApi";
import { GET_QUESTION_SENTIMENTS_REQUEST } from "./actionTypes";

function* getSentimentsOfQuestion({ payload: surveyPayload }) {
  let isThisQuiz = window.location.pathname.includes("quiz");
  try {
    const response = yield call(
      questionSentimentsAnalysisApi,
      isThisQuiz
        ? { ...surveyPayload, quiz_id: surveyPayload.survey_id }
        : surveyPayload
    );
    if (response && response.success) {
      yield put(getQuestionSentimentsSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getQuestionSentimentsError(error));
  }
}

function* assessmentSentimentsSaga() {
  yield takeEvery(GET_QUESTION_SENTIMENTS_REQUEST, getSentimentsOfQuestion);
}

export default assessmentSentimentsSaga;
