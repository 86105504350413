import { loadingMessage } from "../../pages/Chat/components/chatData";
import {
  ADD_AND_UPDATE_MESSAGE,
  ADD_MESSAGE,
  EDIT_MESSAGE,
  RESET_CHAT,
} from "./actionType";

import _ from "lodash";

const INIT_STATE_CHAT = [loadingMessage];

const Chats = (state = INIT_STATE_CHAT, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      _.remove(state, function (num) {
        return num.message == "loading";
      });
      return [...state, ...action.payload];

    case ADD_AND_UPDATE_MESSAGE:
      return [...action.payload];

    case EDIT_MESSAGE:
      _.remove(state, function (num) {
        return num.message == "loading";
      });
      return [...state, ...action.payload];

    case RESET_CHAT:
      return INIT_STATE_CHAT;

    default:
      return [...state];
  }
};

export default Chats;
