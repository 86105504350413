import { create } from "../common/http.service";

const GET_FAMILY_TREE = "/get-tree";

const CREATE_NEW_NODE_URL = "/ses-verify-email";
const DELETE_NODE_URL = "/ses-delete-email";
const EDIT_NODE_URL = "/ses-delete-email";

export const createNodeApi = async (payload) => {
  const res = await create(CREATE_NEW_NODE_URL, payload, true);

  return res;
};

export const getFamilyTreeApi = async (payload) => {
  const res = await create(GET_FAMILY_TREE, payload, true);

  return res;
};

export const deleteNodeApi = async (payload) => {
  const res = await create(DELETE_NODE_URL, payload, true);

  return res;
};

export const editNodeApi = async (payload) => {
  const res = await create(EDIT_NODE_URL, payload, true);
  return res;
};
