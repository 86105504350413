import {
	ACTIVE_MEMBER_ERROR,
	ACTIVE_MEMBER_REQUEST,
	ACTIVE_MEMBER_RESET,
	ACTIVE_MEMBER_SUCCESS,
	ADD_MEMBER_EMAIL_ERROR,
	ADD_MEMBER_EMAIL_REQUEST,
	ADD_MEMBER_EMAIL_RESET,
	ADD_MEMBER_EMAIL_SUCCESS,
	ADD_MEMBER_ERROR,
	ADD_MEMBER_REQUEST,
	ADD_MEMBER_RESET,
	ADD_MEMBER_SUCCESS,
	DELETE_MEMBER_ERROR,
	DELETE_MEMBER_REQUEST,
	DELETE_MEMBER_SUCCESS,
	EDIT_MEMBER_ERROR,
	EDIT_MEMBER_REQUEST,
	EDIT_MEMBER_RESET,
	EDIT_MEMBER_SUCCESS,
	GET_MEMBER_ERROR,
	GET_MEMBER_REQUEST,
	GET_MEMBER_RESET,
	GET_MEMBER_SUCCESS,
	GET_VERIFIED_MEMBER_ERROR,
	GET_VERIFIED_MEMBER_REQUEST,
	GET_VERIFIED_MEMBER_RESET,
	GET_VERIFIED_MEMBER_SUCCESS,
} from './actionTypes';

const initialStateAddMemberEmail = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateAddMember = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateActiveMember = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetMember = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetVerifiedMember = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteMember = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditMember = {
	successData: null,
	error: null,
	loading: null,
};

const addMemberEmail = (state = initialStateAddMemberEmail, action) => {
	switch (action.type) {
		case ADD_MEMBER_EMAIL_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;
		case ADD_MEMBER_EMAIL_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_MEMBER_EMAIL_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_MEMBER_EMAIL_RESET:
			state = initialStateAddMemberEmail;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const addMember = (state = initialStateAddMember, action) => {
	switch (action.type) {
		case ADD_MEMBER_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;
		case ADD_MEMBER_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_MEMBER_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_MEMBER_RESET:
			state = initialStateAddMember;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

const activeMember = (state = initialStateActiveMember, action) => {
	switch (action.type) {
		case ACTIVE_MEMBER_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;
		case ACTIVE_MEMBER_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ACTIVE_MEMBER_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ACTIVE_MEMBER_RESET:
			state = initialStateActiveMember;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getMember = (state = initialStateGetMember, action) => {
	switch (action.type) {
		case GET_MEMBER_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case GET_MEMBER_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_MEMBER_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_MEMBER_RESET:
			state = initialStateGetMember;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getVerifiedMember = (state = initialStateGetVerifiedMember, action) => {
	switch (action.type) {
		case GET_VERIFIED_MEMBER_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case GET_VERIFIED_MEMBER_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_VERIFIED_MEMBER_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_VERIFIED_MEMBER_RESET:
			state = initialStateGetVerifiedMember;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteMember = (state = initialStateDeleteMember, action) => {
	switch (action.type) {
		case DELETE_MEMBER_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_MEMBER_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_MEMBER_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editMember = (state = initialStateEditMember, action) => {
	switch (action.type) {
		case EDIT_MEMBER_REQUEST:
			state = {
				error: null,
				successData: {},
				loading: true,
			};
			break;
		case EDIT_MEMBER_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_MEMBER_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_MEMBER_RESET:
			state = initialStateEditMember;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export {
	activeMember,
	addMember,
	addMemberEmail,
	deleteMember,
	editMember,
	getMember,
	getVerifiedMember,
};
