import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//import images

import { MdAccountCircle, MdLogout } from "react-icons/md";
import { NavLink } from "react-router-dom";
import DecryptedUser from "../../common/DecryptedUser";

let defaultImage =
  "https://smart-antlere.s3.ap-southeast-1.amazonaws.com/images/system_images/avatar-blank.png";

const ProfileDropdown = () => {
  const { View_Profile, View_Settings } = useSelector(
    (state) => state.assignPermissions
  );

  const { decryptUser } = DecryptedUser();

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={
                decryptUser.profile_pic_url !== "null"
                  ? decryptUser.profile_pic_url
                  : defaultImage
              }
              alt="Header Avatar"
              width={32}
              height={32}
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {decryptUser?.first_name} {decryptUser?.last_name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                User ID {decryptUser.id}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {decryptUser?.first_name}!
          </h6>

          {View_Profile ? (
            <DropdownItem>
              <NavLink
                to={"/profile/1"}
                className=" d-block"
                style={{ color: "inherit" }}
              >
                <MdAccountCircle className="h2 text-muted align-middle me-1 mb-0" />
                <span className="align-middle">Profile</span>
              </NavLink>
            </DropdownItem>
          ) : (
            <DropdownItem
              href={process.env.REACT_APP_UPGRADE_REDIRECT}
              target={"_blank"}
              className="upgrade-share d-flex align-items-center"
            >
              <MdAccountCircle className="h2 text-muted align-middle me-1 mb-0" />
              <span className="align-middle">Profile</span>
            </DropdownItem>
          )}

          {/* {View_Settings ? (
            <NavLink to={"/profile-settings"} className="dropdown-item">
              <MdSettings className="h2 text-muted align-middle me-1 mb-0" />

              <span className="align-middle">Settings</span>
            </NavLink>
          ) : (
            <DropdownItem
              href={process.env.REACT_APP_UPGRADE_REDIRECT}
              target={"_blank"}
              className="upgrade-share d-flex align-items-center"
            >
              <MdSettings className="h2 text-muted align-middle me-1 mb-0" />
              <span className="align-middle">Settings</span>
            </DropdownItem>
          )} */}

          {/* <NavLink to={"/billing"} className="dropdown-item">
            <MdOutlineCreditCard className="h2 text-muted align-middle me-1 mb-0" />
            <span className="align-middle">Billing</span>
          </NavLink> */}

          <NavLink to={"/logout"} className="dropdown-item">
            <MdLogout className="h2 text-muted align-middle me-1 mb-0" />
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </NavLink>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
