import {
  VIEW_ALL_ASSESSMENT_ERROR,
  VIEW_ALL_ASSESSMENT_REQUEST,
  VIEW_ALL_ASSESSMENT_RESET,
  VIEW_ALL_ASSESSMENT_STATS_ERROR,
  VIEW_ALL_ASSESSMENT_STATS_REQUEST,
  VIEW_ALL_ASSESSMENT_STATS_RESET,
  VIEW_ALL_ASSESSMENT_STATS_SUCCESS,
  VIEW_ALL_ASSESSMENT_SUCCESS,
} from "./actionTypes";

const initialStateViewAllAssessment = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateViewAllAssessmentStats = {
  successData: null,
  error: null,
  loading: null,
};

const viewAllAssessment = (state = initialStateViewAllAssessment, action) => {
  const { keepState } = action.payload || {};
  switch (action.type) {
    case VIEW_ALL_ASSESSMENT_REQUEST:
      state = keepState
        ? {
            ...state,
            error: null,
            loading: true,
          }
        : {
            successData: null,
            error: null,
            loading: true,
          };
      break;
    case VIEW_ALL_ASSESSMENT_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case VIEW_ALL_ASSESSMENT_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case VIEW_ALL_ASSESSMENT_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const viewAllAssessmentStats = (
  state = initialStateViewAllAssessmentStats,
  action
) => {
  switch (action.type) {
    case VIEW_ALL_ASSESSMENT_STATS_REQUEST:
      state = {
        successData: null,
        error: null,
        loading: true,
      };
      break;
    case VIEW_ALL_ASSESSMENT_STATS_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case VIEW_ALL_ASSESSMENT_STATS_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case VIEW_ALL_ASSESSMENT_STATS_RESET:
      state = initialStateViewAllAssessmentStats;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { viewAllAssessment, viewAllAssessmentStats };
