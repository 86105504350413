export const ADD_MEMBER_EMAIL_REQUEST = 'ADD_MEMBER_EMAIL_REQUEST';
export const ADD_MEMBER_EMAIL_SUCCESS = 'ADD_MEMBER_EMAIL_SUCCESS';
export const ADD_MEMBER_EMAIL_ERROR = 'ADD_MEMBER_EMAIL_ERROR';
export const ADD_MEMBER_EMAIL_RESET = 'ADD_MEMBER_EMAIL_RESET';

export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_ERROR = 'ADD_MEMBER_ERROR';
export const ADD_MEMBER_RESET = 'ADD_MEMBER_RESET';

export const ACTIVE_MEMBER_REQUEST = 'ACTIVE_MEMBER_REQUEST';
export const ACTIVE_MEMBER_SUCCESS = 'ACTIVE_MEMBER_SUCCESS';
export const ACTIVE_MEMBER_ERROR = 'ACTIVE_MEMBER_ERROR';
export const ACTIVE_MEMBER_RESET = 'ACTIVE_MEMBER_RESET';

export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_ERROR = 'GET_MEMBER_ERROR';
export const GET_MEMBER_RESET = 'GET_MEMBER_RESET';

export const GET_VERIFIED_MEMBER_REQUEST = 'GET_VERIFIED_MEMBER_REQUEST';
export const GET_VERIFIED_MEMBER_SUCCESS = 'GET_VERIFIED_MEMBER_SUCCESS';
export const GET_VERIFIED_MEMBER_ERROR = 'GET_VERIFIED_MEMBER_ERROR';
export const GET_VERIFIED_MEMBER_RESET = 'GET_VERIFIED_MEMBER_RESET';

export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';
export const DELETE_MEMBER_RESET = 'DELETE_MEMBER_RESET';

export const EDIT_MEMBER_REQUEST = 'EDIT_MEMBER_REQUEST';
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
export const EDIT_MEMBER_ERROR = 'EDIT_MEMBER_ERROR';
export const EDIT_MEMBER_RESET = 'EDIT_MEMBER_RESET';
