import {
	ADD_USER_ROLE_ERROR,
	ADD_USER_ROLE_REQUEST,
	ADD_USER_ROLE_RESET,
	ADD_USER_ROLE_SUCCESS,
	DELETE_USER_ROLE_ERROR,
	DELETE_USER_ROLE_REQUEST,
	DELETE_USER_ROLE_SUCCESS,
	EDIT_USER_ROLE_ERROR,
	EDIT_USER_ROLE_REQUEST,
	EDIT_USER_ROLE_RESET,
	EDIT_USER_ROLE_SUCCESS,
	GET_USER_ROLE_ERROR,
	GET_USER_ROLE_REQUEST,
	GET_USER_ROLE_RESET,
	GET_USER_ROLE_SUCCESS,
} from './actionTypes';

const initialStateAddUserRole = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetUserRole = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteUserRole = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditUserRole = {
	successData: null,
	error: null,
	loading: null,
};

const addUserRole = (state = initialStateAddUserRole, action) => {
	switch (action.type) {
		case ADD_USER_ROLE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_USER_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_USER_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_USER_ROLE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getUserRole = (state = initialStateGetUserRole, action) => {
	switch (action.type) {
		case GET_USER_ROLE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_USER_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_USER_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_USER_ROLE_RESET:
			state = initialStateGetUserRole;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteUserRole = (state = initialStateDeleteUserRole, action) => {
	switch (action.type) {
		case DELETE_USER_ROLE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_USER_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_USER_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editUserRole = (state = initialStateEditUserRole, action) => {
	switch (action.type) {
		case EDIT_USER_ROLE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_USER_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_USER_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_USER_ROLE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addUserRole, deleteUserRole, getUserRole, editUserRole };
