import {
  ADD_NEW_NODE_ERROR,
  ADD_NEW_NODE_REQUEST,
  ADD_NEW_NODE_RESET,
  ADD_NEW_NODE_SUCCESS,
  DELETE_NODE_ERROR,
  DELETE_NODE_REQUEST,
  DELETE_NODE_SUCCESS,
  EDIT_NODE_ERROR,
  EDIT_NODE_REQUEST,
  EDIT_NODE_RESET,
  EDIT_NODE_SUCCESS,
  GET_FAMILY_TREE_ERROR,
  GET_FAMILY_TREE_REQUEST,
  GET_FAMILY_TREE_RESET,
  GET_FAMILY_TREE_SUCCESS,
} from "./actionTypes";

const initialStateAddNewNode = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateGetFamilyTree = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateDeleteNode = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateEditNode = {
  successData: null,
  error: null,
  loading: null,
};

const addNewNode = (state = initialStateAddNewNode, action) => {
  switch (action.type) {
    case ADD_NEW_NODE_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;
    case ADD_NEW_NODE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case ADD_NEW_NODE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case ADD_NEW_NODE_RESET:
      state = initialStateAddNewNode;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const getFamilyTree = (state = initialStateGetFamilyTree, action) => {
  switch (action.type) {
    case GET_FAMILY_TREE_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;
    case GET_FAMILY_TREE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case GET_FAMILY_TREE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_FAMILY_TREE_RESET:
      state = initialStateGetFamilyTree;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const deleteNode = (state = initialStateDeleteNode, action) => {
  switch (action.type) {
    case DELETE_NODE_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;
    case DELETE_NODE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case DELETE_NODE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const editNode = (state = initialStateEditNode, action) => {
  switch (action.type) {
    case EDIT_NODE_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;
    case EDIT_NODE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case EDIT_NODE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case EDIT_NODE_RESET:
      state = initialStateEditNode;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { addNewNode, deleteNode, editNode, getFamilyTree };
