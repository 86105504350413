import {
  ADD_QUESTION_AT_END,
  ADD_QUESTION_IN_BETWEEN,
  ADD_QUESTION_TITLE,
  ALL_QUESTION_COMPLETE_VIEW,
  ALL_QUESTION_SURVEY,
  EDIT_QUESTION,
  EDIT_QUESTION_DATA,
  EDIT_QUESTION_DATA_RESET,
  QUESTION_SURVEY_RESET,
} from "./actionTypes";

const initialStateQuestions = [];
const initialStateEditQuestionData = {};
const initialStateQuestionsCompleteView = [];

const assessmentQuestionsCompleteView = (
  state = initialStateQuestionsCompleteView,
  action
) => {
  switch (action.type) {
    case ALL_QUESTION_COMPLETE_VIEW:
      state = action.payload;

      break;

    case QUESTION_SURVEY_RESET:
      state = initialStateQuestionsCompleteView;
      break;

    default:
      state = [...state];
      break;
  }
  return state;
};
const assessmentQuestions = (state = initialStateQuestions, action) => {
  switch (action.type) {
    case ADD_QUESTION_TITLE:
      var { index, nameTitle } = action.payload;
      state[index] = {
        ...state[index],
        name: nameTitle,
      };
      state = [...state];
      break;

    case ALL_QUESTION_SURVEY:
      state = action.payload;

      break;
    case ADD_QUESTION_AT_END:
      state = [...state, action.payload];

      break;

    case ADD_QUESTION_IN_BETWEEN:
      state = action.payload;

      break;

    case QUESTION_SURVEY_RESET:
      state = [];
      break;

    default:
      state = [...state];
      break;
  }
  return state;
};
const editQuestionInfo = (state = initialStateEditQuestionData, action) => {
  switch (action.type) {
    case EDIT_QUESTION:
      state = { ...state, ...action.payload };
      break;

    case EDIT_QUESTION_DATA:
      state = action.payload;
      break;

    case EDIT_QUESTION_DATA_RESET:
      state = { ...initialStateEditQuestionData };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export {
  assessmentQuestions,
  assessmentQuestionsCompleteView,
  editQuestionInfo,
};
