import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import { getAIQuestionsError, getAIQuestionsSuccess } from "./actions";

import { getAIQuestionApi } from "../../../../services/surveys/create-survey/getAIQuestionApi";
import { GET_AI_QUESTIONS_REQUEST } from "./actionTypes";

function* getAIQuestions({ payload: question }) {
  try {
    const response = yield call(getAIQuestionApi, question);
    if (response && response.success) {
      yield put(getAIQuestionsSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getAIQuestionsError(error));
  }
}

function* questionsAISaga() {
  yield takeEvery(GET_AI_QUESTIONS_REQUEST, getAIQuestions);
}

export default questionsAISaga;
