import {
	CREATE_CHART_DASHBOARD_ERROR,
	CREATE_CHART_DASHBOARD_REQUEST,
	CREATE_CHART_DASHBOARD_RESET,
	CREATE_CHART_DASHBOARD_SUCCESS,
	DELETE_CHART_DASHBOARD_ERROR,
	DELETE_CHART_DASHBOARD_REQUEST,
	DELETE_CHART_DASHBOARD_RESET,
	DELETE_CHART_DASHBOARD_SUCCESS,
	UPDATE_CHART_DASHBOARD_ERROR,
	UPDATE_CHART_DASHBOARD_REQUEST,
	UPDATE_CHART_DASHBOARD_RESET,
	UPDATE_CHART_DASHBOARD_SUCCESS,
	VIEW_CHART_DASHBOARD_ERROR,
	VIEW_CHART_DASHBOARD_REQUEST,
	VIEW_CHART_DASHBOARD_RESET,
	VIEW_CHART_DASHBOARD_SUCCESS,
	VIEW_PUBLIC_DASHBOARD_ERROR,
	VIEW_PUBLIC_DASHBOARD_REQUEST,
	VIEW_PUBLIC_DASHBOARD_RESET,
	VIEW_PUBLIC_DASHBOARD_SUCCESS,
	VIEW_SINGLE_CHART_DASHBOARD_ERROR,
	VIEW_SINGLE_CHART_DASHBOARD_REQUEST,
	VIEW_SINGLE_CHART_DASHBOARD_RESET,
	VIEW_SINGLE_CHART_DASHBOARD_SUCCESS,
} from './actionTypes';

// View All Dashboards

export const viewChartDashboardRequest = (surveyPayload) => {
	return {
		type: VIEW_CHART_DASHBOARD_REQUEST,
		payload: surveyPayload,
	};
};

export const viewChartDashboardSuccess = (message) => {
	return {
		type: VIEW_CHART_DASHBOARD_SUCCESS,
		payload: message,
	};
};

export const viewChartDashboardError = (message) => {
	return {
		type: VIEW_CHART_DASHBOARD_ERROR,
		payload: message,
	};
};

export const viewChartDashboardReset = () => {
	return {
		type: VIEW_CHART_DASHBOARD_RESET,
	};
};

// View Single Dashboard
export const viewSingleChartDashboardRequest = (surveyPayload) => {
	return {
		type: VIEW_SINGLE_CHART_DASHBOARD_REQUEST,
		payload: surveyPayload,
	};
};

export const viewSingleChartDashboardSuccess = (message) => {
	return {
		type: VIEW_SINGLE_CHART_DASHBOARD_SUCCESS,
		payload: message,
	};
};

export const viewSingleChartDashboardError = (message) => {
	return {
		type: VIEW_SINGLE_CHART_DASHBOARD_ERROR,
		payload: message,
	};
};

export const viewSingleChartDashboardReset = () => {
	return {
		type: VIEW_SINGLE_CHART_DASHBOARD_RESET,
	};
};

// Create Dashboard
export const createChartDashboardRequest = (surveyPayload) => {
	return {
		type: CREATE_CHART_DASHBOARD_REQUEST,
		payload: surveyPayload,
	};
};

export const createChartDashboardSuccess = (message) => {
	return {
		type: CREATE_CHART_DASHBOARD_SUCCESS,
		payload: message,
	};
};

export const createChartDashboardError = (message) => {
	return {
		type: CREATE_CHART_DASHBOARD_ERROR,
		payload: message,
	};
};

export const createChartDashboardReset = () => {
	return {
		type: CREATE_CHART_DASHBOARD_RESET,
	};
};

// Update Dashboard
export const updateChartDashboardRequest = (surveyPayload) => {
	return {
		type: UPDATE_CHART_DASHBOARD_REQUEST,
		payload: surveyPayload,
	};
};

export const updateChartDashboardSuccess = (message) => {
	return {
		type: UPDATE_CHART_DASHBOARD_SUCCESS,
		payload: message,
	};
};

export const updateChartDashboardError = (message) => {
	return {
		type: UPDATE_CHART_DASHBOARD_ERROR,
		payload: message,
	};
};

export const updateChartDashboardReset = () => {
	return {
		type: UPDATE_CHART_DASHBOARD_RESET,
	};
};

// Delete Dashboard
export const deleteChartDashboardRequest = (surveyPayload) => {
	return {
		type: DELETE_CHART_DASHBOARD_REQUEST,
		payload: surveyPayload,
	};
};

export const deleteChartDashboardSuccess = (message) => {
	return {
		type: DELETE_CHART_DASHBOARD_SUCCESS,
		payload: message,
	};
};

export const deleteChartDashboardError = (message) => {
	return {
		type: DELETE_CHART_DASHBOARD_ERROR,
		payload: message,
	};
};

export const deleteChartDashboardReset = () => {
	return {
		type: DELETE_CHART_DASHBOARD_RESET,
	};
};

// View public dashboard

export const viewPublicDashboardRequest = (surveyPayload) => {
	return {
		type: VIEW_PUBLIC_DASHBOARD_REQUEST,
		payload: surveyPayload,
	};
};

export const viewPublicDashboardSuccess = (message) => {
	return {
		type: VIEW_PUBLIC_DASHBOARD_SUCCESS,
		payload: message,
	};
};

export const viewPublicDashboardError = (message) => {
	return {
		type: VIEW_PUBLIC_DASHBOARD_ERROR,
		payload: message,
	};
};

export const viewPublicDashboardReset = () => {
	return {
		type: VIEW_PUBLIC_DASHBOARD_RESET,
	};
};
