import axios from "axios";

const AppAxios = ({ headers, isPrivate, withCredentials = false }) => {
  // const tokenId = acookie;

  const tokenId = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : null;

  const Axios = axios.create({
    baseURL: process.env.REACT_APP_BASIC_URL,
    headers: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_BASIC_URL,
      "content-type": "application/json",
      withCredentials,
      ...headers,
    },
  });
  // For requests
  Axios.interceptors.request.use(
    (req) => {
      // Add configurations here
      if (isPrivate) {
        req.headers["Authorization"] = `Bearer ${tokenId}`;
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  // For response
  Axios.interceptors.response.use(
    (res) => {
      // Add configurations here
      if (res.status === 201) {
        console.log("Posted Successfully");
      }
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return Axios;
};

export default AppAxios;

// export const axiosWithParams = {
// 	headers: {
// 		'Content-Type': 'application/json',
// 		withCredentials: true,
// 	},
// };
