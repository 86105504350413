import {
  GET_QUESTION_SENTIMENTS_ERROR,
  GET_QUESTION_SENTIMENTS_REQUEST,
  GET_QUESTION_SENTIMENTS_RESET,
  GET_QUESTION_SENTIMENTS_SUCCESS,
} from "./actionTypes";

const initialStateSurveyAnalysis = {
  successData: null,
  error: null,
  loading: null,
};

// Question Sentiments

const questionSentiments = (state = initialStateSurveyAnalysis, action) => {
  switch (action.type) {
    case GET_QUESTION_SENTIMENTS_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;

    case GET_QUESTION_SENTIMENTS_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case GET_QUESTION_SENTIMENTS_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_QUESTION_SENTIMENTS_RESET:
      state = initialStateSurveyAnalysis;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { questionSentiments };
