import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const contactUs = require("../../assets/images/dashboard-icons/Contact_Us.svg");
const dashboard = require("../../assets/images/dashboard-icons/Dashboard.svg");
const faqs = require("../../assets/images/dashboard-icons/FAQs.svg");
const integrations = require("../../assets/images/dashboard-icons/Integrations.svg");
const organizations = require("../../assets/images/dashboard-icons/Organization.svg");
const reports = require("../../assets/images/dashboard-icons/Reports.svg");
const settings = require("../../assets/images/dashboard-icons/Settings.svg");
const surveys = require("../../assets/images/dashboard-icons/Survey.svg");
const virtual_tour = require("../../assets/images/dashboard-icons/Virtual_Tour.svg");
const question_bank = require("../../assets/images/dashboard-icons/question-bank.png");
const user_package = require("../../assets/images/dashboard-icons/package-svgrepo-com.svg");
const node_tree = require("../../assets/images/dashboard-icons/node-tree-svgrepo-com.svg");

import { BsGear, BsHouseDoor, BsPersonGear } from "react-icons/bs";
import DecryptedUser from "../../common/DecryptedUser";

const Navdata = () => {
  const userId = JSON.parse(localStorage.getItem("userID"));
  const history = useHistory();
  //state data

  const [isDashboard, setIsDashboard] = useState(false);

  const [isAuth, setIsAuth] = useState(false);
  const [isRole, setIsRole] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [isConfig, setIsConfig] = useState(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  const { decryptUser } = DecryptedUser();

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");

      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (isCurrentState !== "Admin") {
      setIsAdmin(false);
    }

    if (isCurrentState !== "Auth") {
      setIsAuth(false);
    }

    if (isCurrentState !== "Team") {
      setIsTeam(false);
    }
    if (isCurrentState !== "Config") {
      setIsConfig(false);
    }
    // if (isCurrentState == 'Admin') {
    // 	if (document.body.classList[0] == 'twocolumn-panel') {
    // 		document.body.classList.remove('twocolumn-panel');
    // 	} else {
    // 		document.body.classList.add('twocolumn-panel');
    // 	}
    // }
    // if (
    // 	isCurrentState == 'Dashboard' &&
    // 	document.body.classList[0] == 'twocolumn-panel'
    // ) {
    // 	document.body.classList.remove('twocolumn-panel');
    // }
  }, [history, isCurrentState, isDashboard, isAdmin, isAuth, isTeam, isConfig]);

  const menuItems = [
    {
      label: "Navigation",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon_fill: dashboard,
      icon_outline: <BsHouseDoor />,
      link: "/dashboard",
    },
    {
      label: "Application",
      isHeader: true,
    },

    {
      id: "surveys",
      label: "Surveys",
      icon_fill: surveys,
      icon_outline: <BsPersonGear />,
      link: "/survey-list",
    },

    // process.env.REACT_APP_CONTENT_USER?.split(",").some(
    //   (item) => item == decryptUser.email
    // )
    //   ? {
    //       id: "question_bank",
    //       label: "Question Bank",
    //       icon_fill: question_bank,
    //       icon_outline: <BsPersonGear />,
    //       link: "/question-bank",
    //     }
    //   : null,

    // {
    //   id: "reports",
    //   label: "Reports",
    //   icon_fill: reports,
    //   icon_outline: <BsPersonGear />,
    //   link: "#",
    // },
    // {
    //   id: "settings",
    //   label: "Settings",
    //   icon_fill: settings,
    //   icon_outline: <BsGear />,
    //   link: "/profile-settings",
    // },
    {
      id: "admin",
      label: "Administration",
      icon_fill: organizations,
      icon_outline: <BsPersonGear />,
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsAdmin(!isAdmin);
        setIsCurrentState("Admin");
        updateIconSidebar(e);
      },
      stateVariables: isAdmin,
      subItems: [
        {
          id: "roles",
          label: "Roles",
          link: "/#",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsRole(!isRole);
          },
          parentId: "admin",
          stateVariables: isRole,
          childItems: [
            {
              id: 3,
              label: "All Roles",
              link: "/view-roles",
              parentId: "admin",
            },
            {
              id: 2,
              label: "Role Permissions",
              link: "/role-permissions",
              parentId: "admin",
            },
          ],
        },
        {
          id: "team",
          label: "Team",
          // icon: 'ri-group-2-fill',
          link: "/team-management",
          // isChildItem: true,
          parentId: "admin",
        },
      ],
    },

    userId == 1 && {
      id: "permissions",
      label: "Permissions",
      icon_fill: reports,
      icon_outline: <BsPersonGear />,
      link: "/view-permissions",
    },

    decryptUser &&
      decryptUser.reference_code == "0" && {
        id: "config",
        label: "Integrations",
        icon_fill: integrations,
        icon_outline: <BsPersonGear />,
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsConfig(!isConfig);
          setIsCurrentState("Config");
          updateIconSidebar(e);
        },
        stateVariables: isConfig,
        subItems: [
          {
            id: "email-verification",
            label: "Email Verification",
            // icon: 'ri-group-2-fill',
            link: "/email-management",
            // isChildItem: true,
            parentId: "config",
          },
        ],
      },

    userId == 1 && {
      id: "user_package",
      label: "Packages",
      icon_fill: user_package,
      icon_outline: <BsPersonGear />,
      link: "/user-package",
    },
    userId == 1 && {
      id: "family_Tree",
      label: "Family Tree",
      icon_fill: node_tree,
      icon_outline: <BsPersonGear />,
      link: "/tree-map",
    },

    {
      label: "Help",
      isHeader: true,
    },

    {
      id: "virtual_tour",
      label: "Virtual Tour",
      icon_fill: virtual_tour,
      icon_outline: <BsPersonGear />,
      link: "/virtual-tour",
    },
    {
      id: "faq",
      label: "FAQ's",
      icon_fill: faqs,
      icon_outline: <BsPersonGear />,
      link: "/faqs",
    },
    {
      id: "contact_us",
      label: "Contact Us",
      icon_fill: contactUs,
      icon_outline: <BsGear />,
      link: { pathname: process.env.REACT_APP_CONTACT_REDIRECT },
    },

    // {
    // 	id: 'information',
    // 	label: 'KBS',
    // 	icon: 'ri-information-fill',
    // 	link: '/information',
    // },
  ].filter(Boolean);

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
