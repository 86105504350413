import {
	ADD_AUDIENCE_ATTRIBUTES_ERROR,
	ADD_AUDIENCE_ATTRIBUTES_REQUEST,
	ADD_AUDIENCE_ATTRIBUTES_RESET,
	ADD_AUDIENCE_ATTRIBUTES_SUCCESS,
	DELETE_AUDIENCE_ATTRIBUTES_ERROR,
	DELETE_AUDIENCE_ATTRIBUTES_REQUEST,
	DELETE_AUDIENCE_ATTRIBUTES_SUCCESS,
	EDIT_AUDIENCE_ATTRIBUTES_ERROR,
	EDIT_AUDIENCE_ATTRIBUTES_REQUEST,
	EDIT_AUDIENCE_ATTRIBUTES_RESET,
	EDIT_AUDIENCE_ATTRIBUTES_SUCCESS,
	GET_AUDIENCE_ATTRIBUTES_ERROR,
	GET_AUDIENCE_ATTRIBUTES_REQUEST,
	GET_AUDIENCE_ATTRIBUTES_RESET,
	GET_AUDIENCE_ATTRIBUTES_SUCCESS,
} from './actionTypes';

// Create AudienceAttributes
export const addAudienceAttributesRequest = (attributes) => {
	return {
		type: ADD_AUDIENCE_ATTRIBUTES_REQUEST,
		payload: attributes,
	};
};

export const addAudienceAttributesSuccess = (message) => {
	return {
		type: ADD_AUDIENCE_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const addAudienceAttributesError = (message) => {
	return {
		type: ADD_AUDIENCE_ATTRIBUTES_ERROR,
		payload: message,
	};
};

export const addAudienceAttributesReset = () => {
	return {
		type: ADD_AUDIENCE_ATTRIBUTES_RESET,
	};
};

// Get AudienceAttributes
export const getAudienceAttributesRequest = (surveyID) => {
	return {
		type: GET_AUDIENCE_ATTRIBUTES_REQUEST,
		payload: surveyID,
	};
};

export const getAudienceAttributesSuccess = (message) => {
	return {
		type: GET_AUDIENCE_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const getAudienceAttributesError = (message) => {
	return {
		type: GET_AUDIENCE_ATTRIBUTES_ERROR,
		payload: message,
	};
};

export const getAudienceAttributesReset = (message) => {
	return {
		type: GET_AUDIENCE_ATTRIBUTES_RESET,
	};
};

// Delete AudienceAttributes
export const deleteAudienceAttributesRequest = (role) => {
	return {
		type: DELETE_AUDIENCE_ATTRIBUTES_REQUEST,
		payload: role,
	};
};

export const deleteAudienceAttributesSuccess = (message) => {
	return {
		type: DELETE_AUDIENCE_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const deleteAudienceAttributesError = (message) => {
	return {
		type: DELETE_AUDIENCE_ATTRIBUTES_ERROR,
		payload: message,
	};
};

// Delete AudienceAttributes
export const editAudienceAttributesRequest = (role, history) => {
	return {
		type: EDIT_AUDIENCE_ATTRIBUTES_REQUEST,
		payload: { role, history },
	};
};

export const editAudienceAttributesSuccess = (message) => {
	return {
		type: EDIT_AUDIENCE_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const editAudienceAttributesError = (message) => {
	return {
		type: EDIT_AUDIENCE_ATTRIBUTES_ERROR,
		payload: message,
	};
};
export const editAudienceAttributesReset = () => {
	return {
		type: EDIT_AUDIENCE_ATTRIBUTES_RESET,
	};
};
