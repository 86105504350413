import {
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_IMAGE_ERROR,
  PROFILE_IMAGE_REQUEST,
  PROFILE_IMAGE_SUCCESS,
  PROFILE_REQUEST,
  PROFILE_RESET,
  PROFILE_SUCCESS,
  RESET_EDIT_PROFILE_FLAG,
  RESET_PROFILE_IMAGE_FLAG,
} from "./actionTypes";

const initialStateGetProfile = {
  loading: null,
  error: null,
  success: null,
  userProfile: null,
};
const initialStateEditProfile = {
  loading: null,
  error: null,
  success: null,
};

const initialStateProfileImage = {
  loading: null,
  error: null,
  success: null,
};

const getProfile = (state = initialStateGetProfile, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      state = {
        loading: true,
        error: null,
        success: null,
        userProfile: null,
      };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        userProfile: action.payload,
      };
      break;
    case PROFILE_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;

    case PROFILE_RESET:
      state = { ...initialStateGetProfile };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
const editProfile = (state = initialStateEditProfile, action) => {
  switch (action.type) {
    case EDIT_PROFILE_REQUEST:
      state = {
        loading: true,
        error: null,
        success: null,
      };
      break;
    case EDIT_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case EDIT_PROFILE_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;

    case RESET_EDIT_PROFILE_FLAG:
      state = { ...initialStateEditProfile };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
const profileImage = (state = initialStateProfileImage, action) => {
  switch (action.type) {
    case PROFILE_IMAGE_REQUEST:
      state = {
        loading: true,
        error: null,
        success: null,
      };
      break;
    case PROFILE_IMAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case PROFILE_IMAGE_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;

    case RESET_PROFILE_IMAGE_FLAG:
      state = { ...initialStateProfileImage };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export { editProfile, getProfile, profileImage };
